import React from "react";
import MaterialTable from "material-table";

const Table = props => {
  const tableColorStyle = { backgroundColor: "#ebf5d6" };
  let { options, ...otherProps } = props;
  options = {
    ...options,
    rowStyle: tableColorStyle,
    headerStyle: tableColorStyle
  };
  return (
    <MaterialTable style={tableColorStyle} options={options} {...otherProps} />
  );
};

export default Table;
