import camelCase from "lodash/camelCase";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import uuidv4 from "uuid/v4";
import { routes } from "../../constants";
import { convertToCase, formatMeasStationData } from "../../utils/misc";

export default function useMeasStationSetup(type) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [measStationData, setMeasStationData] = useState(null);
  const [fileName, setFileName] = useState(null);

  useEffect(
    function() {
      if (measStationData) {
        let formattedData = {};
        if (!measStationData.columnNames) {
          let sensorValues = [];
          let columnNames = [];
          // Extract all column_names and format them correctly
          for (const sensor of measStationData.sensorValues) {
            const { column_names, sensorName, ...sensorData } = sensor;
            sensorValues.push(sensorData);
            for (const columnName in column_names) {
              const formattedColumnName = {
                id: uuidv4(),
                sensorConfigUuid: sensor.id,
                name: columnName,
                ...column_names[columnName]
              };
              const camelColumnName = convertToCase(
                formattedColumnName,
                camelCase
              );
              columnNames.push(camelColumnName);
            }
          }

          formattedData = formatMeasStationData({
            ...measStationData,
            sensorValues,
            columnNames
          });
        } else {
          formattedData = formatMeasStationData(measStationData);
        }

        history.push(routes.measurementStation, {
          measStationData: formattedData,
          fileName: fileName,
          // Type param passed to hook takes preference over type response
          // from server
          type: type || formattedData.type
        });
        setIsLoading(false);
        setIsSubmitting(false);
      }
    },
    [fileName, history, measStationData, type]
  );

  return [
    isLoading,
    setIsLoading,
    isSubmitting,
    setIsSubmitting,
    setMeasStationData,
    setFileName
  ];
}
