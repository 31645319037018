import React from "react";
import MUICircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  progressWrapper: {
    marginTop: theme.spacing(16)
  }
}));

function CircularProgress() {
  const classes = useStyles();
  return (
    <Grid container justify="center" className={classes.progressWrapper}>
      <Grid item>
        <MUICircularProgress color="secondary" />
      </Grid>
    </Grid>
  );
}

export default CircularProgress;
