import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import CircularProgress from "../global/CircularProgress";
import { getAsCamel } from "../../utils/http";
import useMeasStationSetup from "./useMeasStationSetup";

function EditMeasurementStationForm() {
  const [plants, setPlants] = useState([]);
  const [measLocations, setMeasLocations] = useState([]);
  const [isLoading, setIsLoading, , , setMeasStationData] = useMeasStationSetup(
    "edit"
  );

  useEffect(() => {
    const getMeasurementLocations = async () => {
      setPlants(await getAsCamel("plants"));
      setMeasLocations(await getAsCamel("measurement-locations"));
      setIsLoading(false);
    };

    setIsLoading(true);
    getMeasurementLocations();
  }, [setIsLoading]);

  const getPlant = measLocation => {
    return plants.find(plant => {
      return measLocation.plantUuid === plant.id;
    });
  };

  const handleEditClicked = async measLocation => {
    setIsLoading(true);
    let measStationData = {};
    if (localStorage.getItem(measLocation.id) !== null) {
      measStationData = JSON.parse(localStorage.getItem(measLocation.id));
    } else {
      // Remove unneeded data
      const {
        tableData,
        plantName,
        plantType,
        ...measLocationData
      } = measLocation;
      const loggerParams = {
        measurement_location_uuid: measLocationData.id
      };
      const loggers = await getAsCamel("logger-main-configs", loggerParams);
      const measPointParams = {
        measurement_location_uuid: measLocationData.id
      };
      const measPoints = await getAsCamel(
        "measurement-points",
        measPointParams
      );
      let sensors = [];

      for (const logger of loggers) {
        const params = {
          logger_main_config_uuid: logger.id
        };
        const loggerSensors = await getAsCamel("sensor-configs", params, [
          "column_names"
        ]);
        sensors = sensors.concat(loggerSensors);
      }

      measStationData = {
        plantValues: getPlant(measLocationData),
        measurementLocationValues: measLocationData,
        loggerValues: loggers,
        measurementPointValues: measPoints,
        sensorValues: sensors
      };
    }
    setMeasStationData(measStationData);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const tableData = measLocations.map(measLocation => {
    const plant = getPlant(measLocation);
    return {
      ...measLocation,
      plantName: plant.name,
      plantType: plant.plantType
    };
  });

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "Name", field: "name" },
          {
            title: "Plant",
            field: "plantName"
          },
          {
            title: "Type",
            field: "plantType"
          }
        ]}
        data={tableData}
        title="Measurement Stations"
        actions={[
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, measLocation) => handleEditClicked(measLocation)
          }
        ]}
        options={{
          paging: false,
          // Overrides paddingLeft on Actions column name being set as 4
          headerStyle: { paddingLeft: 14 }
        }}
      />
    </div>
  );
}

export default EditMeasurementStationForm;
