import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "../../constants";

const useStyles = makeStyles(theme => ({
  contentHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentNoPadding: {
    padding: 0
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));

export default function MainPageContent({ drawerOpen, isSiteMap, children }) {
  const classes = useStyles();

  return (
    <main
      className={clsx(
        classes.content,
        {
          [classes.contentShift]: drawerOpen
        },
        { [classes.contentNoPadding]: isSiteMap }
      )}
    >
      <div className={classes.contentHeader} />
      {children}
    </main>
  );
}
