import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import auth0Client from "../../Auth";
import MapMarker, { markerSize } from "./MapMarker";
import MapControl from "./MapControl";

function getMapOptions(maps) {
  return {
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.TERRAIN,
    minZoom: 2,
    fullscreenControl: false
  };
}

const useLatLongBoxStyles = makeStyles(() => ({
  paper: {
    margin: 10,
    padding: 10
  }
}));

function LatLongBox({ latitude, longitude }) {
  const classes = useLatLongBoxStyles();
  return (
    <Paper className={classes.paper}>
      <Typography>
        {latitude}, {longitude}
      </Typography>
    </Paper>
  );
}

const useSiteMapStyles = makeStyles(theme => ({
  root: {
    // Height needs to be set explicitly for google-map-react
    height: `calc(100vh - 64px)`,
    [theme.breakpoints.down("xs")]: {
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`
    },
    width: "100%"
  }
}));

function SiteMap() {
  const classes = useSiteMapStyles();
  const [measurementLocations, setMeasurementLocations] = useState([]);
  const initialLat = 20;
  const initialLong = 0;
  const [latitude, setLatitude] = useState(initialLat);
  const [longitude, setLongitude] = useState(initialLong);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);

  useEffect(() => {
    const getMeasurementLocations = async () => {
      const accessToken = auth0Client.getAccessToken();
      const headers = { Authorization: `Bearer ${accessToken}` };
      let plantMappings = {};
      await axios
        .get(process.env.REACT_APP_RDB_API_URL + "plants", {
          headers
        })
        .then(function(response) {
          for (const plant of response.data) {
            plantMappings[plant.id] = {
              name: plant.name,
              type: plant.plant_type
            };
          }
        });

      await axios
        .get(process.env.REACT_APP_RDB_API_URL + "measurement-locations", {
          headers
        })
        .then(function(response) {
          const measLocations = response.data;
          for (const measLocation of measLocations) {
            measLocation["type"] = plantMappings[measLocation.plant_uuid].type;
            measLocation["plantName"] =
              plantMappings[measLocation.plant_uuid].name;
          }
          setMeasurementLocations(response.data);
        });
    };

    getMeasurementLocations();
  }, []);

  const addMouseListener = (map, maps) => {
    maps.event.addListener(map, "mousemove", event => {
      const pnt = event.latLng;
      let lat = pnt.lat();
      lat = lat.toFixed(6);
      let lng = pnt.lng();
      lng = lng.toFixed(6);
      setLatitude(lat);
      setLongitude(lng);
    });
  };

  return (
    <div className={classes.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
        defaultCenter={{
          lat: initialLat,
          lng: initialLong
        }}
        defaultZoom={3}
        onGoogleApiLoaded={({ map, maps }) => {
          addMouseListener(map, maps);
          setMap(map);
          setMaps(maps);
        }}
        hoverDistance={markerSize / 2}
        options={getMapOptions}
        yesIWantToUseGoogleMapApiInternals
      >
        {measurementLocations.map(measLoc => (
          <MapMarker
            key={measLoc.name}
            lat={measLoc.latitude}
            lng={measLoc.longitude}
            measurementLocation={measLoc}
          />
        ))}
        {/* <MapControl
          map={map}
          controlPosition={maps ? maps.ControlPosition.BOTTOM : null}
        >
          <LatLongBox latitude={latitude} longitude={longitude} />
        </MapControl> */}
      </GoogleMapReact>
    </div>
  );
}

export default SiteMap;
