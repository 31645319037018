import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import MUIAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Drawer from "./Drawer";
import MainPageContent from "./MainPageContent";
import auth0Client from "../../Auth";
import { routes, drawerWidth } from "../../constants";
import bwLogoReversed from "../../images/Brightwind_Logo_Reversed_Alt_188x25.png";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  title: {
    flexGrow: 1
  }
}));

function AppBar({ checkingSession, children }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { isAuthenticated } = auth0Client;
  const isLandingPage = location.pathname === routes.landingPage;
  const isSiteMap = location.pathname === routes.siteMap;

  function handleDrawerOpen() {
    setDrawerOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteLocalStorage = () => {
    localStorage.clear();
    handleDialogClose();
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <MUIAppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: drawerOpen
          })}
        >
          <Toolbar>
            {!isLandingPage && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, drawerOpen && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <div className={classes.title}>
              <NavLink
                to={isAuthenticated() ? routes.siteMap : routes.landingPage}
                className={isLandingPage ? null : classes.navLink}
              >
                <img src={bwLogoReversed} alt="logo" />
              </NavLink>
            </div>
            {!checkingSession && isAuthenticated() && (
              <React.Fragment>
                <Button color="inherit" onClick={() => setDialogOpen(true)}>
                  Delete Local Storage
                </Button>
                <Button color="inherit" onClick={auth0Client.signOut}>
                  Logout
                </Button>
              </React.Fragment>
            )}
            {!checkingSession && !isAuthenticated() && (
              <Button color="inherit" onClick={auth0Client.signIn}>
                Sign In
              </Button>
            )}
          </Toolbar>
        </MUIAppBar>
        <Drawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          history={history}
          location={location}
        />
        <MainPageContent drawerOpen={drawerOpen} isSiteMap={isSiteMap}>
          {children}
        </MainPageContent>
      </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Delete Local Storage</DialogTitle>
        <DialogContent>
          WARNING: Any local data stored on your computer that hasn't been
          uploaded to the server will be deleted. Are you sure you want to
          continue?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
          <Button onClick={handleDeleteLocalStorage} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AppBar;
