import React, { useCallback, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { fileValuesReducer, fileErrorsReducer } from "./reducers";
import CircularProgress from "../global/CircularProgress";
import { actionTypes } from "../../constants";
import { logAxiosError } from "../../utils/errorHandling";
import { getAuthHeaders } from "../../utils/http";
import useFormState from "../global/useFormState";
import BaseForm from "../global/BaseForm";
import SubmissionForm from "../global/SubmissionForm";
import { hasErrors } from "../../utils/forms";
import useMeasStationSetup from "./useMeasStationSetup";

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  centeredButton: {
    display: "flex",
    justifyContent: "center"
  }
}));

function UploadLoggerFileForm({ setErrorMessage }) {
  const classes = useStyles();
  const [
    isLoading,
    setIsLoading,
    isSubmitting,
    setIsSubmitting,
    setMeasStationData,
    setFileName
  ] = useMeasStationSetup();

  const handleHttpError = useCallback(
    error => {
      setIsLoading(false);
      logAxiosError(error);
      setErrorMessage(
        "Unable to make a connection to the server. Please try again later."
      );
    },
    [setErrorMessage, setIsLoading]
  );

  const [values, errors, handleValueChange, handleValuesSubmit] = useFormState(
    fileValuesReducer,
    fileErrorsReducer,
    setIsSubmitting,
    actionTypes.validateManyFields,
    { loggerFile: {} }
  );

  useEffect(() => {
    const getMeasStationData = async () => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("logger_file", values.loggerFile);
      const headers = getAuthHeaders();
      headers["Content-Type"] = "multipart/form-data";

      return axios
        .post(process.env.REACT_APP_RDB_API_URL + "logger-file", formData, {
          headers
        })
        .then(function() {
          return axios
            .get(process.env.REACT_APP_RDB_API_URL + "generated-json", {
              headers,
              params: {
                file_name: values.loggerFile.name
              }
            })
            .then(function(response) {
              return response.data;
            })
            .catch(handleHttpError);
        })
        .then(function(measStationData) {
          return measStationData;
        })
        .catch(handleHttpError);
    };

    if (isSubmitting && !hasErrors(errors)) {
      setFileName(values.loggerFile.name);
      if (localStorage.getItem(values.loggerFile.name) !== null) {
        let measStationData = JSON.parse(
          localStorage.getItem(values.loggerFile.name)
        );
        setMeasStationData(measStationData);
      } else {
        getMeasStationData().then(measStationData => {
          setMeasStationData(measStationData);
        });
      }
    }
  }, [
    isSubmitting,
    setIsSubmitting,
    errors,
    values.loggerFile,
    handleHttpError,
    setIsLoading,
    setMeasStationData,
    setFileName
  ]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <BaseForm formTitle="Upload Logger File">
      <SubmissionForm
        handleSubmit={handleValuesSubmit}
        submitButtonLabel="Next"
      >
        <Grid direction="column" container alignItems="center">
          <Grid item>
            <input
              style={{ display: "none" }}
              id="logger-file"
              type="file"
              onChange={handleValueChange(actionTypes.setField, "loggerFile")}
            />
            <label htmlFor="logger-file">
              <Button
                variant="contained"
                component="span"
                color="primary"
                className={classes.button}
              >
                Upload File
                <CloudUploadIcon className={classes.rightIcon} />
              </Button>
            </label>
          </Grid>
          <Grid>
            {values.loggerFile && values.loggerFile.name && (
              <Typography>Logger File: {values.loggerFile.name}</Typography>
            )}
            {errors.loggerFile && (
              <Typography color="error">{errors.loggerFile}</Typography>
            )}
          </Grid>
        </Grid>
      </SubmissionForm>
    </BaseForm>
  );
}

export default UploadLoggerFileForm;
