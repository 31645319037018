import React from "react";
import uuidv4 from "uuid/v4";
import Grid from "@material-ui/core/Grid";
import MeasPointPanel from "./MeasPointPanel";
import SubmissionForm from "../global/SubmissionForm";
import { sortByMeasurementPoint } from "../../utils/misc";

export default function CreateMeasurementPointsForm({
  measPoints,
  addMeasPoint,
  deleteMeasPoint,
  errors,
  handleValueChange,
  handleSubmit,
  handleBack,
  measurementTypes,
  measLocUuid,
}) {
  const sortMeasPoints = (a, b) => {
    return sortByMeasurementPoint(a, b, measurementTypes);
  };
  const sortedMeasPoints = measPoints.slice().sort(sortMeasPoints);

  const measPointPanels = sortedMeasPoints.map((measPoint) => {
    const measPointErrors = errors.filter(
      (error) => error.id === measPoint.id
    )[0];
    return (
      <MeasPointPanel
        key={measPoint.id}
        measPoint={measPoint}
        deleteMeasPoint={deleteMeasPoint}
        errors={measPointErrors}
        measurementTypes={measurementTypes}
        measLocUuid={measLocUuid}
        handleValueChange={handleValueChange}
      />
    );
  });

  return (
    <SubmissionForm
      handleSubmit={handleSubmit}
      submitButtonLabel="Next"
      extraButtonProps={[
        { key: 0, onClick: handleBack, children: "Back" },
        {
          key: 1,
          onClick: () =>
            addMeasPoint({ id: uuidv4(), mountingArrangement: {} }),
          children: "Create New Measurement Point",
        },
      ]}
    >
      <Grid container spacing={3}>
        {measPointPanels}
      </Grid>
    </SubmissionForm>
  );
}
