import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import uuidv4 from "uuid/v4";
import DateTimePicker from "./DateTimePicker";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { getValue, validateRequired } from "../../utils/forms";
import NotificationIcon from "./NotificationIcon";

const useStyles = makeStyles(theme => ({
  deleteButton: {
    backgroundColor: red[700],
    "&:hover": {
      backgroundColor: red[900]
    }
  },
  notificationIcon: { height: "100%" },
  expansionPanelRoot: {
    backgroundColor: "#F5FAEA"
  }
}));

function ConfigPanel({
  config,
  setNewConfigs,
  setConfigIdToDelete,
  panelSummaryContent,
  panelDetailsContent,
  configName,
  deleteInfoText,
  hideChangeButton,
  loggerHasChangesFromServer,
  configChangesFromServer,
  hasColumnChanges
}) {
  const classes = useStyles();
  const [newDateFrom, setNewDateFrom] = useState(null);
  const [newDateFromError, setNewDateFromError] = useState("");
  const [changeDialogOpen, setChangeDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleChangeDialogClose = () => {
    setChangeDialogOpen(false);
    setNewDateFrom(null);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleChangeConfigSubmit = () => {
    const error = validateRequired(newDateFrom);
    if (error) {
      setNewDateFromError(error);
    } else {
      setNewConfigs([
        {
          ...config,
          dateFrom: new Date(newDateFrom),
          id: uuidv4()
        }
      ]);
      setChangeDialogOpen(false);
    }
    setNewDateFrom(null);
  };

  const handleDeleteConfigSubmit = () => {
    setConfigIdToDelete(config.id);
    setDeleteDialogOpen(false);
  };

  let showNotificationIcon = false;
  let notificationText = "";
  if (loggerHasChangesFromServer) {
    showNotificationIcon = true;
    notificationText = "Automatically generated for new logger";
  } else if (
    configChangesFromServer &&
    Object.keys(configChangesFromServer).length > 0
  ) {
    showNotificationIcon = true;
    notificationText = "Contains changes";
  } else if (hasColumnChanges) {
    showNotificationIcon = true;
    notificationText = "Contains new columns";
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <ExpansionPanel
          TransitionProps={{ unmountOnExit: true }}
          classes={{
            root: classes.expansionPanelRoot
          }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            id={"panel-" + config.id + "-header"}
          >
            <Grid container>
              <Grid item xs={11}>
                <Grid container direction="column">
                  {panelSummaryContent}
                </Grid>
              </Grid>
              {showNotificationIcon && (
                <Grid item xs={1}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    className={classes.notificationIcon}
                  >
                    <Grid item>
                      <NotificationIcon title={notificationText} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={3}>
              {panelDetailsContent}
            </Grid>
          </ExpansionPanelDetails>
          <ExpansionPanelActions>
            {!hideChangeButton && (
              <Button
                variant="contained"
                onClick={() => setChangeDialogOpen(true)}
                size="small"
                color="primary"
              >
                Record Config Change
              </Button>
            )}
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={() => setDeleteDialogOpen(true)}
              size="small"
              color="primary"
            >
              {"Delete " + configName}
            </Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
      </Grid>
      <Dialog open={changeDialogOpen} onClose={handleChangeDialogClose}>
        <DialogTitle>When did the change occur?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the first timestamp recorded by the new config.
          </DialogContentText>
          <form>
            <DateTimePicker
              required
              label="Date from"
              value={newDateFrom}
              error={newDateFromError}
              onChange={event => setNewDateFrom(getValue(event))}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangeConfigSubmit} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Delete this {configName}?</DialogTitle>
        {deleteInfoText && (
          <DialogContent>
            <DialogContentText>{deleteInfoText}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfigSubmit} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ConfigPanel;
