import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => ({
  avatar: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main
  }
}));

export default function NotificationIcon({ title, scale }) {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <Avatar
        style={scale && { transform: `scale(${scale})` }}
        className={classes.avatar}
      >
        <PriorityHigh />
      </Avatar>
    </Tooltip>
  );
}
