import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import yellow from "@material-ui/core/colors/yellow";
import bwLogo from "../../images/Brightwind_Logo_Icon_Alt.png";
import clsx from "clsx";

export const markerSize = 30;
const useStyles = makeStyles(theme => ({
  icon: {
    position: "absolute",
    width: markerSize,
    height: markerSize,
    left: -markerSize / 2,
    top: -markerSize / 2
  },
  windIcon: {
    border: "2px solid;",
    borderColor: theme.palette.secondary.main
  },
  solarIcon: {
    border: "2px solid;",
    borderColor: yellow[500]
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

function MapMarker({ measurementLocation }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);

  return (
    <div>
      <Avatar
        className={clsx(
          classes.icon,
          measurementLocation.type === "solar"
            ? classes.solarIcon
            : classes.windIcon
        )}
        onClick={handleClick}
        src={bwLogo}
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handleClose}
      >
        <Typography gutterBottom>
          Plant: {measurementLocation.plantName}
        </Typography>
        <Typography gutterBottom>
          Measurement Location: {measurementLocation.name}
        </Typography>
        <Typography>
          Measurement Location Uuid: {measurementLocation.id}
        </Typography>
      </Popover>
    </div>
  );
}

export default MapMarker;
