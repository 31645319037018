import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import { globalStyles, actionTypes, DateTimeFormat } from "../../constants";
import { propsAreDifferent } from "../../utils/misc";
import DateTimePicker from "./DateTimePicker";
import TextField from "./TextField";
import ConfigPanel from "./ConfigPanel";
import useItemCreation from "./useItemCreation";
import useItemDeletion from "./useItemDeletion";
import { getNestedErrorValue } from "../../utils/forms";

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
}));

const LoggerPanel = React.memo(
  function({
    logger,
    addLogger,
    deleteLogger,
    previousLoggerId,
    errors,
    handleValueChange,
    createDuplicateSensorConfigs,
    deleteAllSensorsOfLogger,
    configChangesFromServer,
  }) {
    const classes = useStyles();
    const [newLoggers, setNewLoggers] = useItemCreation(
      addLogger,
      handleValueChange,
      [logger.id]
    );
    const [loggerIdToDelete, setLoggerIdToDelete] = useItemDeletion(
      deleteLogger,
      handleValueChange,
      previousLoggerId
    );

    // Need to create duplicate sensors when creating a new logger
    useEffect(
      function() {
        if (newLoggers.length > 0) {
          createDuplicateSensorConfigs(
            logger.id,
            newLoggers[0].id,
            newLoggers[0].dateFrom
          );
        }
      },
      [newLoggers, createDuplicateSensorConfigs, logger.id]
    );

    // Need to delete associated sensors when deleting a logger
    useEffect(
      function() {
        if (loggerIdToDelete !== null) {
          deleteAllSensorsOfLogger(loggerIdToDelete, previousLoggerId);
        }
      },
      [loggerIdToDelete, deleteAllSensorsOfLogger, previousLoggerId]
    );

    const dateFromHeading = logger.dateFrom
      ? moment(logger.dateFrom).format(DateTimeFormat)
      : "";
    const panelSummaryContent = (
      <React.Fragment>
        <Grid item>
          <Typography className={classes.panelHeadingFont}>
            Logger ID: {logger.loggerId}
          </Typography>
        </Grid>
        <Grid item className={classes.panelHeading}>
          <Typography className={classes.panelHeadingFont}>
            Logger Name: {logger.loggerName}
          </Typography>
        </Grid>
        <Grid item className={classes.panelHeading}>
          <Typography className={classes.panelHeadingFont}>
            Logger Serial Number: {logger.loggerSerialNumber}
          </Typography>
        </Grid>
        <Grid item className={classes.panelHeading}>
          <Typography className={classes.panelHeadingFont}>
            Date From: {dateFromHeading}
          </Typography>
        </Grid>
      </React.Fragment>
    );

    const panelDetailsContent = (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography className={classes.formHeader} variant="h6">
            General Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="loggerId"
            name="loggerId"
            label="Logger Id"
            value={logger.loggerId}
            error={errors.loggerId}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "loggerId",
              { id: logger.id }
            )}
            configChangesFromServer={configChangesFromServer}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="loggerOem"
            name="loggerOem"
            label="Logger OEM"
            value={logger.loggerOem}
            error={errors.loggerOem}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "loggerOem",
              { id: logger.id }
            )}
            configChangesFromServer={configChangesFromServer}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="loggerSerialNumber"
            name="loggerSerialNumber"
            label="Logger Serial Number"
            value={logger.loggerSerialNumber}
            error={errors.loggerSerialNumber}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "loggerSerialNumber",
              { id: logger.id }
            )}
            configChangesFromServer={configChangesFromServer}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            required
            label="Date From"
            value={logger.dateFrom}
            error={errors.dateFrom}
            onChange={handleValueChange(actionTypes.setArrayField, "dateFrom", {
              id: logger.id,
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.formHeader} variant="h6">
            Other Details
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <DateTimePicker
            label="Date To"
            value={logger.dateTo}
            error={errors.dateTo}
            onChange={handleValueChange(actionTypes.setArrayField, "dateTo", {
              id: logger.id,
            })}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            id="loggerModel"
            name="loggerModel"
            label="Logger Model"
            value={logger.loggerModel}
            error={errors.loggerModel}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "loggerModel",
              { id: logger.id }
            )}
            configChangesFromServer={configChangesFromServer}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="loggerName"
            name="loggerName"
            label="Logger Name"
            value={logger.loggerName}
            error={errors.loggerName}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "loggerName",
              { id: logger.id }
            )}
            configChangesFromServer={configChangesFromServer}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="code"
            name="code"
            label="Encryption Code"
            value={logger.encryption.code}
            error={getNestedErrorValue(errors.encryption, "code")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "code",
              { id: logger.id, parentField: "encryption" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="key"
            name="key"
            label="Encryption Key"
            value={logger.encryption.key}
            error={getNestedErrorValue(errors.encryption, "key")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "key",
              { id: logger.id, parentField: "encryption" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="timeZone"
            name="timeZone"
            label="Time Zone"
            value={logger.timeZone}
            error={errors.timeZone}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "timeZone",
              { id: logger.id }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            error={errors.samplingRateSec}
            id="samplingRateSec"
            name="samplingRateSec"
            label="Sampling Rate"
            value={logger.samplingRateSec}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "samplingRateSec",
              { id: logger.id }
            )}
            endAdornment="secs"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            error={errors.averagingPeriodMinutes}
            id="averagingPeriodMinutes"
            name="averagingPeriodMinutes"
            label="Averaging Period"
            value={logger.averagingPeriodMinutes}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "averagingPeriodMinutes",
              { id: logger.id }
            )}
            endAdornment="mins"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            id="notes"
            name="notes"
            label="Notes"
            error={errors.notes}
            value={logger.notes}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "notes",
              { id: logger.id }
            )}
          />
        </Grid>
      </React.Fragment>
    );

    const deleteInfoText =
      "Note: All sensor configs associated with this logger will also be deleted and the previous logger's date_to will be reset.";

    return (
      <ConfigPanel
        config={logger}
        setNewConfigs={setNewLoggers}
        setConfigIdToDelete={setLoggerIdToDelete}
        panelSummaryContent={panelSummaryContent}
        panelDetailsContent={panelDetailsContent}
        configName="Logger"
        deleteInfoText={deleteInfoText}
        configChangesFromServer={configChangesFromServer}
      />
    );
  },
  function(prevProps, newProps) {
    if (propsAreDifferent(prevProps, newProps, "logger", [])) {
      return false;
    }

    return true;
  }
);

export default LoggerPanel;
