import React, { useEffect } from "react";
import uuidv4 from "uuid/v4";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { globalStyles, actionTypes } from "../../constants";
import { getNestedErrorValue } from "../../utils/forms";
import DateTimePicker from "./DateTimePicker";
import DatePicker from "./DatePicker";
import TextField from "./TextField";
import Select from "../global/Select";
import ConfigPanel from "./ConfigPanel";
import useItemCreation from "./useItemCreation";
import useItemDeletion from "./useItemDeletion";
import { propsAreDifferent } from "../../utils/misc";
import useTableData from "../global/useTableData";
import Table from "../global/Table";

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
}));

let sensorTypes = [
  { name: "anemometer", id: "anemometer" },
  { name: "ultrasonic", id: "ultrasonic" },
  { name: "wind_vane", id: "wind_vane" },
  { name: "hygro_thermo", id: "hygro_thermo" },
  { name: "humidity", id: "humidity" },
  { name: "thermometer", id: "thermometer" },
  { name: "pressure", id: "pressure" },
  { name: "rain_guage", id: "rain_guage" },
  { name: "logger_voltage", id: "logger_voltage" },
  { name: "logger_current", id: "logger_current" },
  { name: "logger_temperature", id: "logger_temperature" },
  { name: "pyranometer", id: "pyranometer" },
  { name: "weather_st", id: "weather_st" },
  { name: "other", id: "other" },
];

sensorTypes.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

const SensorPanel = React.memo(
  function SensorPanel({
    sensor,
    previousSensorId,
    addSensor,
    deleteSensor,
    loggers,
    measPoints,
    errors,
    handleValueChange,
    sensorColumnNames,
    columnNamesDispatch,
    tableColumns,
    setDialogOpen,
    setSensorIdToAddTo,
    loggerHasChangesFromServer,
    configChangesFromServer,
    hasColumnChanges,
  }) {
    const classes = useStyles();
    const [newSensors, setNewSensors] = useItemCreation(
      addSensor,
      handleValueChange,
      [sensor.id]
    );
    const [sensorIdToDelete, setSensorIdToDelete] = useItemDeletion(
      deleteSensor,
      handleValueChange,
      previousSensorId
    );
    const [
      setColumnToBeAdded,
      setSensorIdToAddColumnTo,
      setColumnToBeUpdated,
      setOldColumnToBeUpdated,
    ] = useTableData(columnNamesDispatch, "sensorConfigUuid", {
      name: null,
      metric: null,
      isIgnored: false,
    });

    // Need to create new column names for newly created sensor
    useEffect(
      function() {
        if (newSensors.length > 0) {
          for (let columnName of sensorColumnNames) {
            columnNamesDispatch({
              type: actionTypes.addItemToArray,
              item: {
                ...columnName,
                id: uuidv4(),
                sensorConfigUuid: newSensors[0].id,
              },
            });
          }
        }
      },
      [newSensors, sensorColumnNames, columnNamesDispatch, setNewSensors]
    );

    // Need to unassign column names of deleted sensor
    useEffect(
      function() {
        if (sensorIdToDelete !== null) {
          for (let columnName of sensorColumnNames) {
            columnNamesDispatch({
              type: actionTypes.setManyArrayFields,
              id: columnName.id,
              values: {
                sensorConfigUuid: null,
                tableData: {},
              },
            });
          }
        }
      },
      [sensorIdToDelete, columnNamesDispatch, sensorColumnNames]
    );

    const sensorIsPyranometer = sensor.sensorInfo.sensorType === "pyranometer";

    let loggerId;
    let loggerName;
    for (const logger of loggers) {
      if (logger.id === sensor.loggerMainConfigUuid) {
        loggerId = logger.loggerId;
        loggerName = logger.loggerName;
      }
    }

    let measPointName;
    for (const measPoint of measPoints) {
      if (measPoint.id === sensor.measurementPointUuid) {
        measPointName = measPoint.name;
      }
    }

    const panelSummaryContent = (
      <React.Fragment>
        <Grid item>
          <Typography className={classes.panelHeadingFont}>
            Measurement Point Name: {measPointName}
          </Typography>
        </Grid>
        <Grid item className={classes.panelHeading}>
          <Typography className={classes.panelHeadingFont}>
            Logger Id: {loggerId}
          </Typography>
        </Grid>
        <Grid item className={classes.panelHeading}>
          <Typography className={classes.panelHeadingFont}>
            Logger Name: {loggerName}
          </Typography>
        </Grid>
        <Grid item className={classes.panelHeading}>
          <Typography className={classes.panelHeadingFont}>
            Column Names:
          </Typography>
        </Grid>
        {sensorColumnNames.map((column) => (
          <Grid key={column.name} item>
            <Typography>{column.name}</Typography>
          </Grid>
        ))}
      </React.Fragment>
    );

    const handleAssignNewColumnsClicked = () => {
      setSensorIdToAddTo(sensor.id);
      setDialogOpen(true);
    };

    const panelDetailsContent = (
      <React.Fragment>
        <Grid item xs={12}>
          <Table
            title="Columns"
            options={{
              search: false,
              paging: false,
            }}
            columns={tableColumns}
            data={sensorColumnNames}
            actions={[
              {
                tooltip: "Assign new columns to sensor",
                isFreeAction: true,
                icon: "assignment_returned",
                onClick: (evt, column) => handleAssignNewColumnsClicked(),
              },
            ]}
            editable={{
              onRowAdd: (newColumn) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    setSensorIdToAddColumnTo(sensor.id);
                    setColumnToBeAdded(newColumn);
                    resolve();
                  }, 600);
                }),
              onRowUpdate: (newColumn, oldColumn) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    setColumnToBeUpdated(newColumn);
                    setOldColumnToBeUpdated(oldColumn);
                    resolve();
                  }, 600);
                }),
              onRowDelete: (oldColumn) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    columnNamesDispatch({
                      type: actionTypes.setManyArrayFields,
                      id: oldColumn.id,
                      values: {
                        sensorConfigUuid: null,
                        tableData: {},
                      },
                    });
                  }, 600);
                }),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.formHeader} variant="h6">
            General Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            value={sensor.loggerMainConfigUuid}
            error={errors.loggerMainConfigUuid}
            name="loggerMainConfigUuid"
            label="Logger"
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "loggerMainConfigUuid",
              { id: sensor.id }
            )}
            choices={loggers.map((logger) => {
              const name =
                logger.loggerId +
                (logger.loggerSerialNumber
                  ? " - " + logger.loggerSerialNumber
                  : "");
              return {
                name,
                id: logger.id,
              };
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            value={sensor.measurementPointUuid}
            error={errors.measurementPointUuid}
            name="measurementPointUuid"
            label="Measurement Point"
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "measurementPointUuid",
              { id: sensor.id }
            )}
            choices={measPoints.map((measPoint) => ({
              name: measPoint.name,
              id: measPoint.id,
            }))}
            includesNone
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            required
            label="Date from"
            value={sensor.dateFrom}
            error={errors.dateFrom}
            onChange={handleValueChange(actionTypes.setArrayField, "dateFrom", {
              id: sensor.id,
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            label="Date to"
            value={sensor.dateTo}
            error={errors.dateTo}
            onChange={handleValueChange(actionTypes.setArrayField, "dateTo", {
              id: sensor.id,
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.formHeader} variant="h6">
            Sensor info
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="sensorOem"
            label="Sensor OEM"
            value={sensor.sensorInfo.sensorOem}
            error={getNestedErrorValue(errors.sensorInfo, "sensorOem")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "sensorOem",
              { id: sensor.id, parentField: "sensorInfo" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="sensorModel"
            label="Sensor model"
            value={sensor.sensorInfo.sensorModel}
            error={getNestedErrorValue(errors.sensorInfo, "sensorModel")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "sensorModel",
              { id: sensor.id, parentField: "sensorInfo" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="sensorSerialNumber"
            label="Sensor serial number"
            value={sensor.sensorInfo.sensorSerialNumber}
            error={getNestedErrorValue(errors.sensorInfo, "sensorSerialNumber")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "sensorSerialNumber",
              { id: sensor.id, parentField: "sensorInfo" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            value={sensor.sensorInfo.sensorType}
            error={getNestedErrorValue(errors.sensorInfo, "sensorType")}
            name="sensorType"
            label="Sensor type"
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "sensorType",
              { id: sensor.id, parentField: "sensorInfo" }
            )}
            choices={sensorTypes}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.formHeader} variant="h6">
            Logger config
          </Typography>
        </Grid>
        {sensorIsPyranometer && (
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              name="loggerSensitivity"
              label="Logger sensitivity"
              value={sensor.loggerConfig.loggerSensitivity}
              error={getNestedErrorValue(
                errors.loggerConfig,
                "loggerSensitivity"
              )}
              handleChange={handleValueChange(
                actionTypes.setNestedArrayField,
                "loggerSensitivity",
                { id: sensor.id, parentField: "loggerConfig" }
              )}
              configChangesFromServer={configChangesFromServer}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            name="loggerSlope"
            label="Logger slope"
            value={sensor.loggerConfig.loggerSlope}
            error={getNestedErrorValue(errors.loggerConfig, "loggerSlope")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "loggerSlope",
              { id: sensor.id, parentField: "loggerConfig" }
            )}
            configChangesFromServer={configChangesFromServer}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            name="loggerOffset"
            label="Logger offset"
            value={sensor.loggerConfig.loggerOffset}
            error={getNestedErrorValue(errors.loggerConfig, "loggerOffset")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "loggerOffset",
              { id: sensor.id, parentField: "loggerConfig" }
            )}
            configChangesFromServer={configChangesFromServer}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="measurementUnits"
            label="Measurement units"
            value={sensor.loggerConfig.measurementUnits}
            error={getNestedErrorValue(errors.loggerConfig, "measurementUnits")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "measurementUnits",
              { id: sensor.id, parentField: "loggerConfig" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            name="loggerStatedHeight"
            label="Logger stated height"
            value={sensor.loggerConfig.loggerStatedHeight}
            error={getNestedErrorValue(
              errors.loggerConfig,
              "loggerStatedHeight"
            )}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "loggerStatedHeight",
              { id: sensor.id, parentField: "loggerConfig" }
            )}
            configChangesFromServer={configChangesFromServer}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="loggerStatedSerial"
            label="Logger stated serial"
            value={sensor.loggerConfig.loggerStatedSerial}
            error={getNestedErrorValue(
              errors.loggerConfig,
              "loggerStatedSerial"
            )}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "loggerStatedSerial",
              { id: sensor.id, parentField: "loggerConfig" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="loggerChannel"
            label="Logger channel"
            value={sensor.loggerConfig.loggerChannel}
            error={getNestedErrorValue(errors.loggerConfig, "loggerChannel")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "loggerChannel",
              { id: sensor.id, parentField: "loggerConfig" }
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.formHeader} variant="h6">
            Desired adjustments
          </Typography>
        </Grid>
        {sensorIsPyranometer && (
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              name="desireSensitivity"
              label="Desired sensitivity"
              value={sensor.desiredAdj.desireSensitivity}
              error={getNestedErrorValue(
                errors.desiredAdj,
                "desireSensitivity"
              )}
              handleChange={handleValueChange(
                actionTypes.setNestedArrayField,
                "desireSensitivity",
                { id: sensor.id, parentField: "desiredAdj" }
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            name="desiredSlope"
            label="Desired slope"
            value={sensor.desiredAdj.desiredSlope}
            error={getNestedErrorValue(errors.desiredAdj, "desiredSlope")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "desiredSlope",
              { id: sensor.id, parentField: "desiredAdj" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            name="desiredOffset"
            label="Desired offset"
            value={sensor.desiredAdj.desiredOffset}
            error={getNestedErrorValue(errors.desiredAdj, "desiredOffset")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "desiredOffset",
              { id: sensor.id, parentField: "desiredAdj" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            name="desiredDirectionOffset"
            label="Desired direction offset"
            value={sensor.desiredAdj.desiredDirectionOffset}
            error={getNestedErrorValue(
              errors.desiredAdj,
              "desiredDirectionOffset"
            )}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "desiredDirectionOffset",
              { id: sensor.id, parentField: "desiredAdj" }
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.formHeader} variant="h6">
            Calibration
          </Typography>
        </Grid>
        {sensorIsPyranometer && (
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              name="calibrationSensitivity"
              label="Sensitivity"
              value={sensor.calibration.sensitivity}
              error={getNestedErrorValue(errors.calibration, "sensitivity")}
              handleChange={handleValueChange(
                actionTypes.setNestedArrayField,
                "sensitivity",
                { id: sensor.id, parentField: "calibration" }
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            name="calibrationSlope"
            label="Slope"
            value={sensor.calibration.slope}
            error={getNestedErrorValue(errors.calibration, "slope")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "slope",
              { id: sensor.id, parentField: "calibration" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            name="calibrationOffset"
            label="Offset"
            value={sensor.calibration.offset}
            error={getNestedErrorValue(errors.calibration, "offset")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "offset",
              { id: sensor.id, parentField: "calibration" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="calibrationReport"
            label="Report"
            value={sensor.calibration.report}
            error={getNestedErrorValue(errors.calibration, "report")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "report",
              { id: sensor.id, parentField: "calibration" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Date"
            value={sensor.calibration.date}
            error={getNestedErrorValue(errors.calibration, "date")}
            onChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "date",
              { id: sensor.id, parentField: "calibration" }
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="calibrationBy"
            label="By"
            value={sensor.calibration.by}
            error={getNestedErrorValue(errors.calibration, "by")}
            onChange={handleValueChange(actionTypes.setNestedArrayField, "by", {
              id: sensor.id,
              parentField: "calibration",
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            id="calibrationNotes"
            name="calibrationNotes"
            label="Notes"
            value={sensor.calibration.notes}
            error={getNestedErrorValue(errors.calibration, "report")}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "notes",
              { id: sensor.id, parentField: "calibration" }
            )}
          />
        </Grid>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <ConfigPanel
          config={sensor}
          columns={sensorColumnNames}
          setNewConfigs={setNewSensors}
          setConfigIdToDelete={setSensorIdToDelete}
          panelSummaryContent={panelSummaryContent}
          panelDetailsContent={panelDetailsContent}
          configName="Sensor"
          loggerHasChangesFromServer={loggerHasChangesFromServer}
          configChangesFromServer={configChangesFromServer}
          hasColumnChanges={hasColumnChanges}
        />
      </React.Fragment>
    );
  },
  function(prevProps, newProps) {
    if (
      propsAreDifferent(prevProps, newProps, "sensor", ["sensorColumnNames"])
    ) {
      return false;
    }

    return true;
  }
);

export default SensorPanel;
