import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import bwLogo from "../../images/Brightwind_Logo_Icon_Transparent-square.png";
import auth0Client from "../../Auth";

const useStyles = makeStyles(theme => ({
  logo: {
    marginTop: theme.spacing(6)
  },
  heroTextContainer: {
    textAlign: "center"
  },
  heroText: {
    marginTop: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(3)
  }
}));

function LandingPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid direction="column" container alignItems="center">
        <Grid item>
          <img src={bwLogo} className={classes.logo} alt="logo" />
        </Grid>
        <Grid item className={classes.heroTextContainer}>
          <Typography variant="h4" color="primary" className={classes.heroText}>
            BrightWind Platform
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            onClick={auth0Client.signIn}
            className={classes.button}
          >
            {auth0Client.isAuthenticated() ? "Enter Platform" : "Sign In"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default LandingPage;
