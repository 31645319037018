import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { routes, drawerWidth } from "../../constants";
import MUIDrawer from "@material-ui/core/Drawer";
import DashboardIcon from "@material-ui/icons/Dashboard";
import List from "@material-ui/core/List";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Collapse from "@material-ui/core/Collapse";
import DrawerListItem from "./DrawerListItem";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
}));

export default function Drawer({ drawerOpen, setDrawerOpen, history }) {
  const classes = useStyles();
  const [measStationsOpen, setMeasStationsOpen] = React.useState(false);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerItemClick = url => {
    history.push(url);
  };

  const handleExpandListClick = (setOpenFn, openState) => {
    setOpenFn(!openState);
  };

  return (
    <MUIDrawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={drawerOpen}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <DrawerListItem
          handleDrawerItemClick={() => handleDrawerItemClick(routes.siteMap)}
          route={routes.siteMap}
          label="Site Map"
          icon={DashboardIcon}
        />
        <DrawerListItem
          handleDrawerItemClick={() =>
            handleExpandListClick(setMeasStationsOpen, measStationsOpen)
          }
          label="Measurement Stations"
          icon={AssessmentIcon}
          hasChildren={true}
          open={measStationsOpen}
        />
        <Collapse in={measStationsOpen} timeout="auto" unmountOnExit>
          <List disablePadding>
            <DrawerListItem
              handleDrawerItemClick={() =>
                handleDrawerItemClick(routes.uploadLoggerFile)
              }
              route={routes.uploadLoggerFile}
              label="Upload Logger File"
              nested={true}
            />
            <DrawerListItem
              handleDrawerItemClick={() =>
                handleDrawerItemClick(routes.editMeasurementStation)
              }
              route={routes.editMeasurementStation}
              label="Edit Station"
              nested={true}
            />
            <DrawerListItem
              handleDrawerItemClick={() =>
                handleDrawerItemClick(routes.cleaning)
              }
              route={routes.cleaning}
              label="Cleaning"
              nested={true}
            />
            <DrawerListItem
              handleDrawerItemClick={() =>
                handleDrawerItemClick(routes.issueLog)
              }
              route={routes.issueLog}
              label="Issue Log"
              nested={true}
            />
          </List>
        </Collapse>
      </List>
    </MUIDrawer>
  );
}
