import React, { useState } from "react";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20,
    opacity: 0.9
  },
  errorIcon: {
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

function ErrorSnackbar({ errorMessage, setErrorMessage }) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setErrorMessage("");
    setOpen(false);
  }

  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={open}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.error}
        aria-describedby="error-snackbar"
        message={
          <span id="error-snackbar" className={classes.message}>
            <ErrorIcon className={clsx(classes.icon, classes.errorIcon)} />
            {errorMessage}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}

export default ErrorSnackbar;
