import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { globalStyles, actionTypes } from "../../constants";
import { getNestedErrorValue } from "../../utils/forms";
import { propsAreDifferent } from "../../utils/misc";
import useItemDeletion from "./useItemDeletion";
import TextField from "./TextField";
import ConfigPanel from "./ConfigPanel";
import Select from "../global/Select";

const useStyles = makeStyles(theme => ({
  ...globalStyles(theme)
}));

export const MeasPointPanelSummaryContent = ({ name }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid item>
        <Typography className={classes.panelHeadingFont}>
          Name: {name}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

const MeasPointPanel = React.memo(
  function({
    measPoint,
    deleteMeasPoint,
    errors,
    measurementTypes,
    handleValueChange
  }) {
    const classes = useStyles();
    const [, setMeasPointIdToDelete] = useItemDeletion(
      deleteMeasPoint,
      handleValueChange
    );

    const panelSummaryContent = (
      <MeasPointPanelSummaryContent name={measPoint.name} />
    );

    const panelDetailsContent = (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography className={classes.formHeader} variant="h6">
            General Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="measurementPointName"
            label="Name"
            value={measPoint.name}
            error={errors.name}
            handleChange={handleValueChange(actionTypes.setArrayField, "name", {
              id: measPoint.id
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            value={measPoint.measurementType}
            error={errors.measurementType}
            name="measurementType"
            label="Measurement type"
            choices={measurementTypes}
            handleChange={handleValueChange(
              actionTypes.setArrayField,
              "measurementType",
              { id: measPoint.id }
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.formHeader} variant="h6">
            Mounting Arrangement
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            id="heightMetres"
            name="heightMetres"
            label="Height"
            value={measPoint.mountingArrangement.heightMetres}
            error={getNestedErrorValue(
              errors.mountingArrangement,
              "heightMetres"
            )}
            handleChange={handleValueChange(
              actionTypes.setNestedArrayField,
              "heightMetres",
              { id: measPoint.id, parentField: "mountingArrangement" }
            )}
            endAdornment="m"
          />
        </Grid>
        {["wind speed", "wind direction", "solar irradiance"].includes(
          measPoint.measurementType
        ) && (
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              id="boomOrientationDeg"
              name="boomOrientationDeg"
              label="Boom orientation"
              value={measPoint.mountingArrangement.boomOrientationDeg}
              error={getNestedErrorValue(
                errors.mountingArrangement,
                "boomOrientationDeg"
              )}
              handleChange={handleValueChange(
                actionTypes.setNestedArrayField,
                "boomOrientationDeg",
                {
                  id: measPoint.id,
                  parentField: "mountingArrangement"
                }
              )}
              endAdornment="deg"
            />
          </Grid>
        )}
        {measPoint.measurementType === "wind direction" && (
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              id="vaneDeadBandOrientationDeg"
              name="vaneDeadBandOrientationDeg"
              label="Vane dead band orientation (deg)"
              value={measPoint.mountingArrangement.vaneDeadBandOrientationDeg}
              error={getNestedErrorValue(
                errors.mountingArrangement,
                "vaneDeadBandOrientationDeg"
              )}
              handleChange={handleValueChange(
                actionTypes.setNestedArrayField,
                "vaneDeadBandOrientationDeg",
                {
                  id: measPoint.id,
                  parentField: "mountingArrangement"
                }
              )}
            />
          </Grid>
        )}
        {measPoint.measurementType === "solar irradiance" && (
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              id="tiltAngleDeg"
              name="tiltAngleDeg"
              label="Tilt angle (deg)"
              value={measPoint.mountingArrangement.tiltAngleDeg}
              error={getNestedErrorValue(
                errors.mountingArrangement,
                "tiltAngleDeg"
              )}
              handleChange={handleValueChange(
                actionTypes.setNestedArrayField,
                "tiltAngleDeg",
                {
                  id: measPoint.id,
                  parentField: "mountingArrangement"
                }
              )}
            />
          </Grid>
        )}
      </React.Fragment>
    );

    const deleteInfoText = "";

    return (
      <ConfigPanel
        config={measPoint}
        setConfigIdToDelete={setMeasPointIdToDelete}
        panelSummaryContent={panelSummaryContent}
        panelDetailsContent={panelDetailsContent}
        configName="measurement point"
        deleteInfoText={deleteInfoText}
        deleteLabel="Delete Measurement Point"
        hideChangeButton={true}
      />
    );
  },
  function(prevProps, newProps) {
    if (propsAreDifferent(prevProps, newProps, "measPoint")) {
      return false;
    }

    return true;
  }
);

export default MeasPointPanel;
