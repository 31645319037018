import React, { useState } from "react";
import camelCase from "lodash/camelCase";
import { makeStyles } from "@material-ui/core/styles";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "../global/Select";
import SensorPanel from "./SensorPanel";
import { actionTypes } from "../../constants";
import SubmissionForm from "../global/SubmissionForm";
import NotificationIcon from "./NotificationIcon";
import useTableData from "../global/useTableData";
import { sortByMeasurementPoint } from "../../utils/misc";
import Table from "../global/Table";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  unassignedColumnsTable: {
    paddingBottom: theme.spacing(4)
  },
  createColumnButton: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const tableColumns = [
  {
    title: "Column Name",
    field: "name"
  },
  {
    title: "Metric",
    field: "metric",
    lookup: {
      Avg: "Avg",
      Max: "Max",
      MaxGust: "MaxGust",
      Min: "Min",
      StdDev: "StdDev",
      Count: "Count",
      Sum: "Sum",
      Qual: "Qual",
      Val: "Val"
    }
  },
  {
    title: "Is Ignored",
    field: "isIgnored",
    type: "boolean"
  }
];

function CreateSensorForm({
  type,
  sensors,
  loggers,
  measPoints,
  measurementTypes,
  addSensor,
  deleteSensor,
  errors,
  handleValueChange,
  handleSubmit,
  handleBack,
  columnNames,
  columnNamesDispatch,
  changedLoggerId,
  configChangesFromServer,
  columnChangesFromServer,
  extraColumns
}) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sensorIdToAddTo, setSensorIdToAddTo] = useState(null);
  const [columnIdToAdd, setColumnIdToAdd] = useState(null);
  const [
    setColumnToBeAdded,
    setSensorIdToAddColumnTo,
    setColumnToBeUpdated,
    setOldColumnToBeUpdated
  ] = useTableData(columnNamesDispatch, "sensorConfigUuid", {
    name: null,
    metric: null,
    isIgnored: false
  });

  const getColumnNamesForSensor = sensor => {
    return columnNames.filter(
      columnName => columnName.sensorConfigUuid === sensor.id
    );
  };

  const sortSensors = (a, b) => {
    const aMeasPoint = measPoints.find(
      measPoint => measPoint.id === a.measurementPointUuid
    );
    const bMeasPoint = measPoints.find(
      measPoint => measPoint.id === b.measurementPointUuid
    );

    if (!bMeasPoint && aMeasPoint) {
      return -1;
    } else if (!aMeasPoint && bMeasPoint) {
      return 1;
    }

    if (aMeasPoint && bMeasPoint) {
      if (
        aMeasPoint.measurementType &&
        bMeasPoint.measurementType &&
        aMeasPoint.measurementType === bMeasPoint.measurementType &&
        bMeasPoint.mountingArrangement.heightMetres ===
          aMeasPoint.mountingArrangement.heightMetres &&
        aMeasPoint.name === bMeasPoint.name
      ) {
        // If everything else is the same, compare by date from
        return a.dateFrom > b.dateFrom ? 1 : -1;
      } else {
        return sortByMeasurementPoint(aMeasPoint, bMeasPoint, measurementTypes);
      }
    }
  };
  const sensorsOrdered = sensors.slice().sort(sortSensors);

  const sensorPanels = sensorsOrdered.map(sensor => {
    const sensorColumnNames = getColumnNamesForSensor(sensor);
    const sensorErrors = errors.filter(error => error.id === sensor.id)[0];

    let sensorConfigChanges = {};
    if (configChangesFromServer && configChangesFromServer.length > 0) {
      for (const configChange of configChangesFromServer) {
        if (configChange.id === sensor.prevId) {
          for (const field in configChange.fields) {
            sensorConfigChanges[camelCase(field)] = configChange.fields[field];
          }
        }
      }
    }

    let hasColumnChanges = false;
    if (columnChangesFromServer && columnChangesFromServer.length > 0) {
      for (const columnChange of columnChangesFromServer) {
        if (columnChange.sensorConfigUuid === sensor.prevId) {
          hasColumnChanges = true;
        }
      }
    }

    let loggerHasChangesFromServer = false;

    const prevSensor = sensors.find(
      otherSensor => otherSensor.id === sensor.prevId
    );
    if (prevSensor && prevSensor.loggerMainConfigUuid === changedLoggerId) {
      loggerHasChangesFromServer = true;
    }

    return (
      <SensorPanel
        key={sensor.id}
        sensor={sensor}
        previousSensorId={sensor.prevId}
        addSensor={addSensor}
        deleteSensor={deleteSensor}
        loggers={loggers}
        measPoints={measPoints}
        errors={sensorErrors}
        handleValueChange={handleValueChange}
        columnNamesDispatch={columnNamesDispatch}
        sensorColumnNames={sensorColumnNames}
        tableColumns={tableColumns}
        setDialogOpen={setDialogOpen}
        setColumnIdToAdd={setColumnIdToAdd}
        setSensorIdToAddTo={setSensorIdToAddTo}
        loggerHasChangesFromServer={loggerHasChangesFromServer}
        configChangesFromServer={sensorConfigChanges}
        hasColumnChanges={hasColumnChanges}
      />
    );
  });

  const unassignedColumns = columnNames.filter(
    columnName => columnName.sensorConfigUuid === null
  );

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAddColumnsToSensor = () => {
    columnNamesDispatch({
      type: actionTypes.setManyArrayFields,
      id: columnIdToAdd,
      values: {
        sensorConfigUuid: sensorIdToAddTo,
        tableData: {}
      }
    });
    setColumnIdToAdd(null);
    setSensorIdToAddTo(null);
    setDialogOpen(false);
  };

  const submitLabelAction = type === "edit" ? "Update" : "Create";

  const unassignedColumnsTableTitle = (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item>Unassigned Columns</Grid>
        {extraColumns && extraColumns.length > 0 && (
          <Grid item>
            <NotificationIcon title={"Column Names added"} scale={0.8} />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );

  return (
    <SubmissionForm
      handleSubmit={handleSubmit}
      submitButtonLabel={`${submitLabelAction} Measurement Station`}
      extraButtonProps={[
        { key: 0, onClick: handleBack, children: "Back" },
        {
          key: 1,
          onClick: () => addSensor({}),
          children: "Create New Blank Sensor"
        }
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.unassignedColumnsTable}>
            <Table
              title={unassignedColumnsTableTitle}
              data={unassignedColumns}
              options={{
                search: false,
                paging: false
              }}
              editable={{
                onRowAdd: newColumn =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      setSensorIdToAddColumnTo(null);
                      setColumnToBeAdded(newColumn);
                      resolve();
                    }, 600);
                  }),
                onRowUpdate: (newColumn, oldColumn) =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      setColumnToBeUpdated(newColumn);
                      setOldColumnToBeUpdated(oldColumn);
                      resolve();
                    }, 600);
                  }),
                onRowDelete: oldColumn =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      columnNamesDispatch({
                        type: actionTypes.deleteItemFromArray,
                        id: oldColumn.id
                      });
                    }, 600);
                  })
              }}
              columns={tableColumns}
            />
          </div>
        </Grid>
        {sensorPanels}
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Select Unassigned Column</DialogTitle>
          <DialogContent>
            {unassignedColumns.length > 0 ? (
              <form>
                <Select
                  value={columnIdToAdd}
                  name="column-select"
                  label="Column"
                  fullWidth
                  useCustomArgs
                  handleChange={event => setColumnIdToAdd(event.target.value)}
                  choices={unassignedColumns}
                />
              </form>
            ) : (
              <DialogContentText>
                All columns are currently assigned to a sensor.
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            {unassignedColumns.length > 0 && (
              <Button
                disabled={columnIdToAdd === null}
                onClick={handleAddColumnsToSensor}
                color="primary"
              >
                Ok
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Grid>
    </SubmissionForm>
  );
}

export default CreateSensorForm;
