import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import camelCase from "lodash/camelCase";
import isEqual from "lodash/isEqual";
import snakeCase from "lodash/snakeCase";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import uuidv4 from "uuid/v4";
import { actionTypes, routes } from "../../constants";
import { logAxiosError } from "../../utils/errorHandling";
import { hasErrors } from "../../utils/forms";
import { getAuthHeaders } from "../../utils/http";
import { convertToCase, formatMeasStationData } from "../../utils/misc";
import CircularProgress from "../global/CircularProgress";
import BaseForm from "../global/BaseForm";
import CreateLoggerMainConfigForm from "./CreateLoggerMainConfigForm";
import CreateMeasurementLocationForm from "./CreateMeasurementLocationForm";
import CreateMeasurementPointsForm from "./CreateMeasurementPointsForm";
import CreatePlantForm from "./CreatePlantForm";
import CreateSensorsForm from "./CreateSensorsForm";
import {
  columnNamesReducer,
  loggerErrorsReducer,
  loggerValuesReducer,
  measLocErrorsReducer,
  measLocValuesReducer,
  measPointErrorsReducer,
  measPointValuesReducer,
  plantErrorsReducer,
  plantValuesReducer,
  prevPlantErrorsReducer,
  prevPlantValuesReducer,
  sensorErrorsReducer,
  sensorValuesReducer,
} from "./reducers";
import Stepper from "./Stepper";
import useFormState from "../global/useFormState";
import useItemCreation from "./useItemCreation";

const steps = [
  "Plant",
  "Measurement Location",
  "Logger Configs",
  "Measurement Points",
  "Sensors",
];

function AddMeasurementStationForm({ setErrorMessage }) {
  const history = useHistory();
  const location = useLocation();
  let { measStationData, fileName, type } = location.state;
  const localStorageKey =
    fileName || measStationData.measurementLocationValues.id;
  // Overwrite the measStationData in the url params if there is corresponding data
  // in the localStorage. This is to ensure that the user gets the most up to date
  // data if they refresh the page
  if (localStorage.getItem(localStorageKey) !== null) {
    measStationData = JSON.parse(localStorage.getItem(localStorageKey));
    measStationData = formatMeasStationData(measStationData);
  }
  const [activeStep, setActiveStep] = useState(0);
  const [serverData, setServerData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [plantInputType, setPlantInputType] = useState("createNewPlant");

  const [
    prevPlantValues,
    prevPlantErrors,
    handlePrevPlantValueChange,
    handlePrevPlantValuesSubmit,
  ] = useFormState(
    prevPlantValuesReducer,
    prevPlantErrorsReducer,
    setIsSubmitting,
    actionTypes.validateManyFields,
    { prevPlantUuid: "" }
  );
  const [
    plantValues,
    plantErrors,
    handlePlantValueChange,
    handlePlantValuesSubmit,
  ] = useFormState(
    plantValuesReducer,
    plantErrorsReducer,
    setIsSubmitting,
    actionTypes.validateManyFields,
    measStationData.plantValues
  );
  const [
    measLocValues,
    measLocErrors,
    handleMeasLocValueChange,
    handleMeasLocValuesSubmit,
  ] = useFormState(
    measLocValuesReducer,
    measLocErrorsReducer,
    setIsSubmitting,
    actionTypes.validateManyFields,
    measStationData.measurementLocationValues
  );
  const [
    loggerValues,
    loggerErrors,
    handleLoggerValuesChange,
    handleLoggerValuesSubmit,
    loggerValuesDispatch,
    loggerErrorsDispatch,
  ] = useFormState(
    loggerValuesReducer,
    loggerErrorsReducer,
    setIsSubmitting,
    actionTypes.validateManyArrayFields,
    measStationData.loggerValues
  );
  const [
    measPointValues,
    measPointErrors,
    handleMeasPointValuesChange,
    handleMeasPointValuesSubmit,
    measPointValuesDispatch,
    measPointErrorsDispatch,
  ] = useFormState(
    measPointValuesReducer,
    measPointErrorsReducer,
    setIsSubmitting,
    actionTypes.validateManyArrayFields,
    measStationData.measurementPointValues
  );

  const [
    sensorValues,
    sensorErrors,
    handleSensorValuesChange,
    handleSensorValuesSubmit,
    sensorValuesDispatch,
    sensorErrorsDispatch,
  ] = useFormState(
    sensorValuesReducer,
    sensorErrorsReducer,
    setIsSubmitting,
    actionTypes.validateManyArrayFields,
    measStationData.sensorValues
  );
  const [columnNames, columnNamesDispatch] = useReducer(
    columnNamesReducer,
    measStationData.columnNames
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [measStationCreated, setMeasStationCreated] = useState(false);
  const [submitButtonsDisabled, setSubmitButtonsDisabled] = useState(false);

  useEffect(() => {
    const errorsByStep = [
      plantInputType === "usePrevPlant" ? prevPlantErrors : plantErrors,
      measLocErrors,
      loggerErrors,
      measPointErrors,
      sensorErrors,
    ];

    if (isSubmitting) {
      if (!hasErrors(errorsByStep[activeStep])) {
        if (activeStep === steps.length - 1) {
          setDialogOpen(true);
        } else {
          setActiveStep(activeStep + 1);
        }
      }
      setIsSubmitting(false);
    }
  }, [
    isSubmitting,
    setIsSubmitting,
    activeStep,
    plantErrors,
    plantInputType,
    prevPlantErrors,
    measLocErrors,
    loggerErrors,
    measPointErrors,
    sensorErrors,
  ]);

  useEffect(() => {
    if (!measStationCreated) {
      const formData = {
        prevPlantValues,
        plantValues,
        // Handlers expect full names so as to match the names that are returned by the server
        // when a file is posted
        measurementLocationValues: measLocValues,
        loggerValues,
        measurementPointValues: measPointValues,
        sensorValues,
        columnNames,
        differingLoggerFields: measStationData.differingLoggerFields,
        differingSensors: measStationData.differingSensors,
        differingColumns: measStationData.differingColumns,
        extraColumns: measStationData.extraColumns,
        newDateFrom: measStationData.newDateFrom,
        type,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(formData));
    }
  }, [
    measStationCreated,
    localStorageKey,
    prevPlantValues,
    plantValues,
    measLocValues,
    loggerValues,
    measPointValues,
    sensorValues,
    columnNames,
    measStationData,
    type,
  ]);

  const handleHttpError = useCallback(
    (error) => {
      setIsLoading(false);
      logAxiosError(error);
      setErrorMessage(
        "Unable to make a connection to the server. Please try again later."
      );
    },
    [setErrorMessage]
  );

  useEffect(() => {
    const getServerData = async () => {
      setIsLoading(true);
      const headers = getAuthHeaders();
      let serverDataResponse = {};

      await axios
        .get(process.env.REACT_APP_RDB_API_URL + "organisations", { headers })
        .then(function(response) {
          serverDataResponse.organisations = response.data;
        });

      await axios
        .get(process.env.REACT_APP_RDB_API_URL + "plants", { headers })
        .then(function(response) {
          serverDataResponse.plants = response.data;
        });

      await axios
        .get(process.env.REACT_APP_RDB_API_URL + "plant-types", { headers })
        .then(function(response) {
          serverDataResponse.plantTypes = response.data;
        });

      await axios
        .get(process.env.REACT_APP_RDB_API_URL + "measurement-station-types", {
          headers,
        })
        .then(function(response) {
          serverDataResponse.measurementStationTypes = response.data;
        });

      await axios
        .get(process.env.REACT_APP_RDB_API_URL + "measurement-types", {
          headers,
        })
        .then(function(response) {
          serverDataResponse.measurementTypes = response.data;
        });

      await axios
        .get(process.env.REACT_APP_RDB_API_URL + "countries", {
          headers,
        })
        .then(function(response) {
          serverDataResponse.countries = response.data;
        });
      setServerData(serverDataResponse);
      setIsLoading(false);
    };

    getServerData();
  }, [handleHttpError]);

  const addItemToArray = (item, dispatchFn, errorsDispatchFn) => {
    const action = { type: actionTypes.addItemToArray, item: item };
    dispatchFn(action);
    // Need to create a new action instead of overwriting previous action,
    // otherwise values and errors will dispatch the same action.
    if (errorsDispatchFn) {
      const errorAction = { ...action, item: { id: item.id } };
      errorsDispatchFn(errorAction);
    }
  };

  const deleteItemFromArray = (id, dispatchFn, errorsDispatchFn) => {
    const action = { type: actionTypes.deleteItemFromArray, id };
    dispatchFn(action);
    if (errorsDispatchFn) {
      const errorAction = { ...action, item: {} };
      errorsDispatchFn(errorAction);
    }
  };

  const addLogger = (logger) => {
    addItemToArray(logger, loggerValuesDispatch, loggerErrorsDispatch);
  };

  const [, setNewLoggers, setPrevLoggerId] = useItemCreation(
    addLogger,
    handleLoggerValuesChange
  );

  const deleteLogger = (id) => {
    deleteItemFromArray(id, loggerValuesDispatch, loggerErrorsDispatch);
  };

  const addMeasPoint = (measPoint) => {
    addItemToArray(
      { name: null, ...measPoint, measurementLocationUuid: measLocValues.id },
      measPointValuesDispatch,
      measPointErrorsDispatch
    );
  };

  const deleteMeasPoint = (id) => {
    deleteItemFromArray(id, measPointValuesDispatch, measPointErrorsDispatch);
  };

  const addSensor = (sensor) => {
    // Use spread operator at end because any fields in it take preference over the default values
    const newSensor = {
      id: uuidv4(),
      dateFrom: null,
      dateTo: null,
      loggerMainConfigUuid: null,
      sensorInfo: {},
      loggerConfig: {},
      desiredAdj: {},
      // Date fields require null values so todays date isn't used in DateFields
      calibration: { date: null },
      ...sensor,
    };
    addItemToArray(newSensor, sensorValuesDispatch, sensorErrorsDispatch);
  };

  const [, setNewSensors, setPrevSensorIds] = useItemCreation(
    addSensor,
    handleSensorValuesChange
  );

  const deleteSensor = (id) => {
    deleteItemFromArray(id, sensorValuesDispatch, sensorErrorsDispatch);
  };

  const addColumnName = (columnName) => {
    addItemToArray(columnName, columnNamesDispatch);
  };

  const deleteColumnName = (id) => {
    deleteItemFromArray(id, columnNamesDispatch);
  };

  const deleteAllSensorsOfLogger = (loggerIdToDelete, previousLoggerId) => {
    for (const sensor of sensorValues) {
      if (sensor.loggerMainConfigUuid === loggerIdToDelete) {
        // Delete all associated sensors
        deleteSensor(sensor.id);
        // Delete all columns of each associated sensor
        for (const columnName of columnNames) {
          if (columnName.sensorConfigUuid === sensor.id) {
            deleteColumnName(columnName.id);
          }
        }
      } else if (sensor.loggerMainConfigUuid === previousLoggerId) {
        // Unset dateTo field of all previous sensors
        sensorValuesDispatch({
          type: actionTypes.setArrayField,
          field: "dateTo",
          id: sensor.id,
          value: null,
        });
      }
    }
  };

  const createDuplicateSensorConfigs = (
    oldLoggerMainConfigUuid,
    newLoggerMainConfigUuid,
    newDateFrom
  ) => {
    const sensorsToCreate = [];
    for (const sensor of sensorValues) {
      if (
        sensor.loggerMainConfigUuid === oldLoggerMainConfigUuid &&
        !sensor.dateTo // Only select current sensors
      ) {
        sensorsToCreate.push(sensor);
      }
    }
    createNewSensorsAndColumns(
      sensorsToCreate,
      newLoggerMainConfigUuid,
      newDateFrom
    );
  };

  const createNewSensorsAndColumns = (
    oldSensors,
    loggerMainConfigId,
    dateFrom,
    extraLoggerConfigFields = [],
    newColumnNames = []
  ) => {
    const newSensors = [];
    const prevSensorIds = [];
    for (const [idx, oldSensor] of oldSensors.entries()) {
      const newSensorId = uuidv4();
      prevSensorIds.push(oldSensor.id);
      let newSensor = {
        ...oldSensor,
        id: newSensorId,
        loggerMainConfigUuid: loggerMainConfigId,
        dateFrom: dateFrom,
      };
      if (extraLoggerConfigFields[idx]) {
        newSensor = {
          ...newSensor,
          loggerConfig: {
            ...newSensor.loggerConfig,
            ...extraLoggerConfigFields[idx],
          },
        };
      }
      newSensors.push(newSensor);
      // Create column names
      if (!newColumnNames.length > 0) {
        for (const columnName of columnNames) {
          if (columnName.sensorConfigUuid === oldSensor.id) {
            addColumnName({
              ...columnName,
              id: uuidv4(),
              sensorConfigUuid: newSensorId,
            });
          }
        }
      } else {
        for (const newColumnName of newColumnNames[idx]) {
          addColumnName({
            ...newColumnName,
            id: uuidv4(),
            sensorConfigUuid: newSensorId,
          });
        }
      }
    }
    setNewSensors(newSensors);
    setPrevSensorIds(prevSensorIds);
  };

  useEffect(() => {
    if (
      measStationData.differingLoggerFields &&
      Object.entries(measStationData.differingLoggerFields).length > 0
    ) {
      const oldLogger = loggerValues.find(
        (logger) => logger.id === measStationData.differingLoggerFields.id
      );
      // Creation of new logger has already been handled
      if (oldLogger.dateTo) {
        return;
      }
      const dateFrom = new Date(measStationData.newDateFrom);
      const differingFieldsCamel = {};
      for (const key in measStationData.differingLoggerFields) {
        if (key !== "id") {
          differingFieldsCamel[camelCase(key)] =
            measStationData.differingLoggerFields[key];
        }
      }
      const newLoggerId = uuidv4();
      setPrevLoggerId([oldLogger.id]);
      setNewLoggers([
        {
          ...oldLogger,
          ...differingFieldsCamel,
          dateFrom,
          id: newLoggerId,
        },
      ]);
      createDuplicateSensorConfigs(oldLogger.id, newLoggerId, dateFrom);
    }
  }, []);

  useEffect(() => {
    if (
      measStationData.differingSensors &&
      measStationData.differingSensors.length > 0
    ) {
      const sensorsToCreate = [];
      const extraLoggerConfigFields = [];
      for (const differingSensor of measStationData.differingSensors) {
        const oldSensor = sensorValues.find(
          (sensor) => sensor.id === differingSensor.id
        );
        // Creation of new sensor has already been handled
        if (oldSensor.dateTo) {
          return;
        }
        const differingFieldsCamel = {};
        for (const key in differingSensor.fields) {
          differingFieldsCamel[camelCase(key)] = differingSensor.fields[key];
        }
        sensorsToCreate.push(oldSensor);
        extraLoggerConfigFields.push(differingFieldsCamel);
      }
      if (sensorsToCreate.length > 0) {
        createNewSensorsAndColumns(
          sensorsToCreate,
          sensorsToCreate[0].loggerMainConfigUuid,
          new Date(measStationData.newDateFrom),
          extraLoggerConfigFields
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      measStationData.differingColumns &&
      measStationData.differingColumns.length > 0
    ) {
      const sensorsToCreate = [];
      const columnNamesToCreate = [];
      for (const differingColumn of measStationData.differingColumns) {
        const oldSensor = sensorValues.find(
          (sensor) => sensor.id === differingColumn.sensorConfigUuid
        );
        // Creation of new sensor has already been handled
        if (oldSensor.dateTo) {
          return;
        }
        sensorsToCreate.push(oldSensor);
        columnNamesToCreate.push(differingColumn.columns);
      }

      if (sensorsToCreate.length > 0) {
        createNewSensorsAndColumns(
          sensorsToCreate,
          sensorsToCreate[0].loggerMainConfigUuid,
          new Date(measStationData.newDateFrom),
          [],
          columnNamesToCreate
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      measStationData.extraColumns &&
      measStationData.extraColumns.length > 0
    ) {
      for (const columnName of measStationData.extraColumns) {
        // Check if column name has already been added
        const alreadyCreatedColumn = columnNames.find((col) => {
          const { id, sensorConfigUuid, tableData, ...otherAttributes } = col;
          if (isEqual(otherAttributes, columnName)) {
            return true;
          } else {
            return false;
          }
        });
        if (alreadyCreatedColumn) {
          continue;
        }

        addColumnName({
          ...columnName,
          id: uuidv4(),
          sensorConfigUuid: null,
        });
      }
    }
  }, []);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmitError = (error) => {
    logAxiosError(error);
    setDialogOpen(false);
    setSubmitButtonsDisabled(false);
    setErrorMessage(
      `Unable to ${type} Measurement Station. Please try again later.`
    );
  };

  const getUrl = (url, resourceId) => {
    let fullUrl = process.env.REACT_APP_RDB_API_URL + url;
    if (type === "add") {
      return fullUrl;
    } else {
      return `${fullUrl}/${resourceId}`;
    }
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  // Gets post params for association tables between organisations and plants.
  const getAssociations = (orgUuids, plantId) => {
    return orgUuids.map((orgUuid) => ({
      plant_uuid: plantId,
      organisation_uuid: orgUuid,
    }));
  };

  // Gets post params for association tables between organisations and plants.
  const getAssociationPromises = (associationParams, url, headers) => {
    // Only ever need to post for developers and owners. If a plant-owner gets duplicate uuids for
    // plant and organisation, it will throw a 500 error. We can ignore this.
    return associationParams.map((params) =>
      axios
        .post(process.env.REACT_APP_RDB_API_URL + url, params, {
          headers,
        })
        .catch(() => {
          // Currently the api returns a 500 error if we try to duplicate a developer or owner.
          // This will always happen if we are PUTing the rest of the data. Therefore, just ignore
          // the error for now.
          return Promise.resolve();
        })
    );
  };

  const submitData = async () => {
    const headers = getAuthHeaders();
    const { developers, owners, ...plantSnakeValues } = convertToCase(
      plantValues,
      snakeCase
    );

    const plantDeveloperValues = getAssociations(
      developers,
      plantSnakeValues.id
    );
    const plantOwnerValues = getAssociations(owners, plantSnakeValues.id);
    const measLocSnakeValues = convertToCase(measLocValues, snakeCase);
    const axiosFn = type === "add" ? axios.post : axios.put;

    if (plantInputType === "usePrevPlant") {
      measLocSnakeValues["plant_uuid"] = prevPlantValues.prevPlantUuid;
    } else {
      await axiosFn(getUrl("plants", plantSnakeValues.id), plantSnakeValues, {
        headers,
      }).catch(function(error) {
        handleSubmitError(error);
      });
    }

    const plantDeveloperPromises = getAssociationPromises(
      plantDeveloperValues,
      "plant-developers",
      headers
    );
    const plantOwnerPromises = getAssociationPromises(
      plantOwnerValues,
      "plant-owners",
      headers
    );

    await Promise.all(plantDeveloperPromises)
      .then(() => {
        return Promise.all(plantOwnerPromises);
      })
      .then(() => {
        return axiosFn(
          getUrl("measurement-locations", measLocSnakeValues.id),
          measLocSnakeValues,
          {
            headers,
          }
        );
      })
      .then(() => {
        const loggerPromises = [];
        for (const logger of loggerValues) {
          const { prevId, ...values } = logger;
          const loggerSnakeValues = convertToCase(values, snakeCase);
          loggerPromises.push(
            axiosFn(
              getUrl("logger-main-configs", loggerSnakeValues.id),
              loggerSnakeValues,
              {
                headers,
              }
            )
          );
        }
        return Promise.all(loggerPromises);
      })
      .then(async () => {
        const measurementPointPromises = [];
        for (const [idx, measPoint] of measPointValues.entries()) {
          const measPointSnakeValues = convertToCase(measPoint, snakeCase);
          measurementPointPromises.push(
            axiosFn(
              getUrl("measurement-points", measPointSnakeValues.id),
              measPointSnakeValues,
              {
                headers,
              }
            )
          );
          if (idx % 10 === 0) {
            await sleep(1000);
          }
        }
        return Promise.all(measurementPointPromises);
      })
      .then(async () => {
        const sensorPromises = [];
        for (const [idx, sensor] of sensorValues.entries()) {
          const { prevId, ...values } = sensor;
          const sensorSnakeValues = convertToCase(values, snakeCase);
          sensorSnakeValues["column_names"] = {};
          for (const columnName of columnNames) {
            if (columnName.sensorConfigUuid === sensorSnakeValues.id) {
              sensorSnakeValues.column_names[columnName.name] = {
                metric: columnName.metric,
                is_ignored: columnName.isIgnored,
              };
            }
          }
          sensorPromises.push(
            axiosFn(
              getUrl("sensor-configs", sensorSnakeValues.id),
              sensorSnakeValues,
              {
                headers,
              }
            )
          );
          if (idx % 10 === 0) {
            await sleep(1000);
          }
        }
        return Promise.all(sensorPromises);
      })
      .then(() => {
        if (fileName) localStorage.removeItem(fileName);
        localStorage.removeItem(measStationData.measurementLocationValues.id);
        setMeasStationCreated(true);
      })
      .catch(function(error) {
        handleSubmitError(error);
      });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = () => {
    setSubmitButtonsDisabled(true);
    submitData();
  };

  const endMeasStationCreation = () => {
    setDialogOpen(false);
    history.push(routes.siteMap);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <CreatePlantForm
            plantInputType={plantInputType}
            setPlantInputType={setPlantInputType}
            values={plantValues}
            errors={plantErrors}
            prevPlantValues={prevPlantValues}
            prevPlantErrors={prevPlantErrors}
            handlePlantValueChange={handlePlantValueChange}
            handlePrevPlantValueChange={handlePrevPlantValueChange}
            handlePlantValuesSubmit={handlePlantValuesSubmit}
            handlePrevPlantValuesSubmit={handlePrevPlantValuesSubmit}
            allPlants={serverData.plants}
            plantTypes={serverData.plantTypes}
            countries={serverData.countries}
            organisations={serverData.organisations}
          />
        );
      case 1:
        return (
          <CreateMeasurementLocationForm
            values={measLocValues}
            errors={measLocErrors}
            handleValueChange={handleMeasLocValueChange}
            handleSubmit={handleMeasLocValuesSubmit}
            handleBack={handleBack}
            measurementStationTypes={serverData.measurementStationTypes}
          />
        );
      case 2:
        return (
          <CreateLoggerMainConfigForm
            loggers={loggerValues}
            errors={loggerErrors}
            handleValueChange={handleLoggerValuesChange}
            handleSubmit={handleLoggerValuesSubmit}
            handleBack={handleBack}
            createDuplicateSensorConfigs={createDuplicateSensorConfigs}
            deleteAllSensorsOfLogger={deleteAllSensorsOfLogger}
            addLogger={addLogger}
            deleteLogger={deleteLogger}
            configChangesFromServer={measStationData.differingLoggerFields}
          />
        );
      case 3:
        return (
          <CreateMeasurementPointsForm
            measPoints={measPointValues}
            addMeasPoint={addMeasPoint}
            deleteMeasPoint={deleteMeasPoint}
            errors={measPointErrors}
            handleValueChange={handleMeasPointValuesChange}
            handleSubmit={handleMeasPointValuesSubmit}
            handleBack={handleBack}
            measurementTypes={serverData.measurementTypes}
            measLocationUuid={measLocValues.id}
          />
        );
      case 4:
        return (
          <CreateSensorsForm
            type={type}
            sensors={sensorValues}
            loggers={loggerValues}
            measPoints={measPointValues}
            measurementTypes={serverData.measurementTypes}
            addSensor={addSensor}
            deleteSensor={deleteSensor}
            errors={sensorErrors}
            handleValueChange={handleSensorValuesChange}
            handleSubmit={handleSensorValuesSubmit}
            handleBack={handleBack}
            columnNames={columnNames}
            columnNamesDispatch={columnNamesDispatch}
            changedLoggerId={
              measStationData.differingLoggerFields &&
              measStationData.differingLoggerFields.id
            }
            configChangesFromServer={measStationData.differingSensors}
            columnChangesFromServer={measStationData.differingColumns}
            extraColumns={measStationData.extraColumns}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  let formTitle = "Add Measurement Station";
  let successMessage = "Measurement Location successfully created.";
  if (type === "edit") {
    formTitle = formTitle.replace("Add", "Edit");
    successMessage = successMessage.replace("created", "edited");
  }

  return (
    <React.Fragment>
      <BaseForm formTitle={formTitle}>
        <Stepper activeStep={activeStep} steps={steps} color="secondary" />
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                {successMessage}
              </Typography>
            </React.Fragment>
          ) : (
            getStepContent()
          )}
        </React.Fragment>
      </BaseForm>
      <Dialog
        open={dialogOpen}
        onClose={
          measStationCreated ? endMeasStationCreation : handleDialogClose
        }
        aria-labelledby="confirm-submission-title"
        aria-describedby="confirm-submission-description"
      >
        <DialogTitle id="confirm-submission-title">
          {measStationCreated
            ? successMessage
            : "Submit this measurement station?"}
        </DialogTitle>
        <DialogActions>
          {measStationCreated ? (
            <Button onClick={endMeasStationCreation} color="primary">
              Close
            </Button>
          ) : (
            <React.Fragment>
              <Button
                disabled={submitButtonsDisabled}
                onClick={handleDialogClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={submitButtonsDisabled}
                onClick={handleDialogSubmit}
                color="primary"
                autoFocus
              >
                Submit
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddMeasurementStationForm;
