import React from "react";
import Grid from "@material-ui/core/Grid";
import camelCase from "lodash/camelCase";
import LoggerPanel from "./LoggerPanel";
import SubmissionForm from "../global/SubmissionForm";

export default function CreateLoggerMainConfigForm({
  loggers,
  errors,
  handleValueChange,
  handleSubmit,
  handleBack,
  changeValue,
  createDuplicateSensorConfigs,
  deleteAllSensorsOfLogger,
  addLogger,
  deleteLogger,
  configChangesFromServer,
}) {
  // Sort by date from
  const sortedLoggers = loggers
    .slice()
    .sort((a, b) => (a.dateFrom > b.dateFrom ? 1 : -1));

  const loggerPanels = sortedLoggers.map((logger) => {
    const loggerErrors = errors.filter((error) => error.id === logger.id)[0];
    let loggerConfigChanges = {};

    if (
      configChangesFromServer &&
      configChangesFromServer.id === logger.prevId
    ) {
      for (const field in configChangesFromServer) {
        if (field !== "id") {
          loggerConfigChanges[camelCase(field)] =
            configChangesFromServer[field];
        }
      }
    }

    return (
      <LoggerPanel
        key={logger.id}
        logger={logger}
        previousLoggerId={logger.prevId}
        addLogger={addLogger}
        deleteLogger={deleteLogger}
        errors={loggerErrors}
        handleValueChange={handleValueChange}
        createDuplicateSensorConfigs={createDuplicateSensorConfigs}
        deleteAllSensorsOfLogger={deleteAllSensorsOfLogger}
        changeValue={changeValue}
        configChangesFromServer={loggerConfigChanges}
      />
    );
  });

  return (
    <SubmissionForm
      handleSubmit={handleSubmit}
      submitButtonLabel="Next"
      extraButtonProps={[{ key: 0, onClick: handleBack, children: "Back" }]}
    >
      <Grid container spacing={3}>
        {loggerPanels}
      </Grid>
    </SubmissionForm>
  );
}
