import { useState, useEffect } from "react";
import { actionTypes } from "../../constants";

export default function useItemDeletion(
  deleteItemFn,
  handleValueChange,
  previousItemId
) {
  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  useEffect(
    function() {
      if (itemIdToDelete !== null) {
        if (previousItemId) {
          handleValueChange(actionTypes.setArrayField, "dateTo", {
            id: previousItemId
          })({
            target: { value: null }
          });
        }
        deleteItemFn(itemIdToDelete);
        setItemIdToDelete(null);
      }
    },
    [itemIdToDelete, handleValueChange, previousItemId, deleteItemFn]
  );

  return [itemIdToDelete, setItemIdToDelete];
}
