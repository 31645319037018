import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "./TextField";
import SubmissionForm from "../global/SubmissionForm";
import Select from "../global/Select";
import { actionTypes } from "../../constants";

export default function CreateMeasurementLocationForm({
  values,
  errors,
  handleValueChange,
  handleSubmit,
  handleBack,
  measurementStationTypes
}) {
  return (
    <SubmissionForm
      handleSubmit={handleSubmit}
      submitButtonLabel="Next"
      extraButtonProps={[{ key: 0, onClick: handleBack, children: "Back" }]}
    >
      <React.Fragment>
        <Grid container spacing={3}>
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6">General Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                name="name"
                label="Measurement Location Name"
                value={values.name}
                error={errors.name}
                handleChange={handleValueChange(actionTypes.setField, "name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                id="latitude"
                name="latitude"
                label="Latitude"
                value={values.latitude}
                error={errors.latitude}
                handleChange={handleValueChange(
                  actionTypes.setField,
                  "latitude"
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                id="longitude"
                name="longitude"
                label="Longitude"
                value={values.longitude}
                error={errors.longitude}
                handleChange={handleValueChange(
                  actionTypes.setField,
                  "longitude"
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                value={values.measurementStationType}
                error={errors.measurementStationType}
                name="measurementStationType"
                label="Type"
                handleChange={handleValueChange(
                  actionTypes.setField,
                  "measurementStationType"
                )}
                choices={measurementStationTypes}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                id="notes"
                name="notes"
                label="Notes"
                value={values.notes}
                error={errors.notes}
                handleChange={handleValueChange(actionTypes.setField, "notes")}
              />
            </Grid>
          </React.Fragment>
        </Grid>
      </React.Fragment>
    </SubmissionForm>
  );
}
