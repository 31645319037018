import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}));

export default function SubmissionForm({
  handleSubmit,
  submitButtonLabel,
  extraButtonProps,
  children
}) {
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} noValidate>
      {children}
      <div className={classes.buttons}>
        {extraButtonProps &&
          extraButtonProps.map(props => (
            <Button {...props} className={classes.button} />
          ))}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onSubmit={handleSubmit}
          className={classes.button}
        >
          {submitButtonLabel || "Next"}
        </Button>
      </div>
    </form>
  );
}
