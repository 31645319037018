import React, { useState, useEffect, useReducer } from "react";
import uuidv4 from "uuid/v4";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment-timezone";
import Select from "../global/Select";
import BaseForm from "../global/BaseForm";
import CircularProgress from "../global/CircularProgress";
import useFormState from "../global/useFormState";
import {
  measLocValuesReducer,
  measLocErrorsReducer,
  cleaningPeriodsReducer,
} from "./reducers";
import { actionTypes, DateTimeFormat } from "../../constants";
import { getAsCamel } from "../../utils/http";
import useTableData from "../global/useTableData";
import { getValue } from "../../utils/forms";
import SubmissionForm from "../global/SubmissionForm";
import useMeasurementLocations from "../global/useMeasurementLocations";
import useLogSubmit from "../IssueLog/useLogSubmit";
import MaterialTable from "material-table";
import { Typography, Button } from "@material-ui/core";
import DateTimePicker from "../MeasurementStations/DateTimePicker";
import ErrorSnackbar from "../global/ErrorSnackbar";
import auth0Client from "../../Auth";

function CleaningForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [measLocs] = useMeasurementLocations(setIsLoading);
  const [measPoints, setMeasPoints] = useState([]);
  const [cleaningReasons, setCleaningReasons] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [cleaningPeriodsSubmitted, setCleaningPeriodsSubmitted] = useState(
    false
  );

  const [cleaningPeriods, cleaningPeriodsDispatch] = useReducer(
    cleaningPeriodsReducer,
    []
  );

  const [
    dialogOpen,
    setDialogOpen,
    isSubmitting,
    setIsSubmitting,
    submissionError,
    setSubmissionError,
  ] = useLogSubmit(
    cleaningPeriods,
    "cleaning-periods/",
    () => setCleaningPeriodsSubmitted(true),
    "Cleaning Period",
    "measurementPointUuid",
    ["reason"]
  );

  const [
    measLocValues,
    measLocErrors,
    handleMeasLocValuesChange,
  ] = useFormState(
    measLocValuesReducer,
    measLocErrorsReducer,
    setIsSubmitting,
    actionTypes.validateManyFields,
    { measLocUuid: "" }
  );

  const [
    setCleaningPeriodToBeAdded,
    setMeasPointIdToAddCleaningPeriodTo,
    setCleaningPeriodToBeUpdated,
    setOldCleaningPeriodToBeUpdated,
  ] = useTableData(cleaningPeriodsDispatch, "measurementPointUuid", {
    dateFrom: null,
    dateTo: null,
    reason: null,
    measurementPointUuid: null,
  });

  useEffect(() => {
    const getCleaningReasons = async () => {
      auth0Client.silentAuth().then(async () => {
        await getAsCamel("cleaning-reasons").then((reasons) => {
          setCleaningReasons(reasons);
        });
      });
    };

    const getMeasurementTypes = async () => {
      await getAsCamel("measurement-types").then((types) => {
        setMeasurementTypes(types);
        setIsLoading(false);
      });
    };

    if (measLocs.length > 0) {
      setIsLoading(true);
      getCleaningReasons();
      getMeasurementTypes();
    }
  }, [measLocs]);

  useEffect(() => {
    const getCleaningPeriods = async () => {
      await getAsCamel("measurement-points", {
        measurement_location_uuid: measLocValues.measLocUuid,
      })
        .then(function(responseCamel) {
          setMeasPoints(responseCamel);
          const promises = responseCamel.map((measPoint) =>
            getAsCamel("cleaning-periods", {
              measurement_point_uuid: measPoint.id,
            })
          );
          return Promise.all(promises);
        })
        .then(function(periods) {
          // Concatenate all periods into one array
          const allCleaningPeriods = [].concat.apply([], periods);
          cleaningPeriodsDispatch({
            type: actionTypes.init,
            item: allCleaningPeriods,
          });
          setIsLoading(false);
        });
    };

    if (measLocValues.measLocUuid) {
      setIsLoading(true);
      getCleaningPeriods();
    }
  }, [measLocValues.measLocUuid]);

  const handleBulkUpdate = (bulkCleaningPeriod) => {
    for (const measPointType of bulkAddTypeChoices[
      bulkCleaningPeriod.measurementPointUuid
    ]) {
      for (const measPoint of measPoints) {
        if (measPoint.measurementType === measPointType) {
          cleaningPeriodsDispatch({
            type: actionTypes.addItemToArray,
            item: {
              id: uuidv4(),
              measurementPointUuid: measPoint.id,
              dateFrom: bulkCleaningPeriod.dateFrom,
              dateTo: bulkCleaningPeriod.dateTo,
              reason: bulkCleaningPeriod.reason,
            },
          });
        }
      }
    }
  };

  if (isLoading || isSubmitting) {
    return <CircularProgress />;
  }

  const reasonLookup = {};
  for (const reason of cleaningReasons) {
    reasonLookup[reason.id] = reason.id;
  }

  const measPointLookup = {
    All: "All",
    "All anemometers": "All anemometers",
    "All anemometers and wind vanes": "All anemometers and wind vanes",
  };
  for (const measPoint of measPoints) {
    measPointLookup[measPoint.id] = measPoint.name;
  }

  const bulkAddTypeChoices = {
    All: measurementTypes.map((measType) => measType.id),
    "All anemometers": ["wind speed"],
    "All wind vanes": ["wind direction"],
    "All anemometers and wind vanes": ["wind speed", "wind direction"],
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const formatDate = (date) => {
    return moment(date).format(DateTimeFormat);
  };

  const editDateComponent = (props) => (
    <DateTimePicker
      // Datepicker requires value to be null if you want to display nothing.
      // props.value is undefined initially, so manually set it to null
      value={props.value || null}
      onChange={(e) => props.onChange(getValue(e))}
    />
  );

  return (
    <React.Fragment>
      <BaseForm formTitle={"Cleaning"} fullWidth>
        <SubmissionForm
          handleSubmit={() => setIsSubmitting(true)}
          submitButtonLabel="Submit"
        >
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Select
                value={measLocValues.measLocUuid}
                error={measLocErrors.measLocUuid}
                name="measLocUuid"
                label="Select Measurement Location"
                handleChange={handleMeasLocValuesChange(
                  actionTypes.setField,
                  "measLocUuid"
                )}
                choices={measLocs}
              />
            </Grid>
            <Grid item xs={12}>
              {measLocValues.measLocUuid && (
                <MaterialTable
                  title="Cleaning"
                  style={{
                    backgroundColor: "#F5FAEA",
                  }}
                  columns={[
                    {
                      title: "Date From",
                      field: "dateFrom",
                      render: (rowData) => (
                        <Typography>{formatDate(rowData.dateFrom)}</Typography>
                      ),
                      editComponent: (props) => {
                        return editDateComponent(props);
                      },
                      customSort: (a, b) => a.dateFrom - b.dateFrom,
                      filtering: false,
                    },
                    {
                      title: "Date To",
                      field: "dateTo",
                      render: (rowData) => (
                        <Typography>{formatDate(rowData.dateTo)}</Typography>
                      ),
                      editComponent: (props) => {
                        return editDateComponent(props);
                      },
                      customSort: (a, b) => a.dateTo - b.dateTo,
                      filtering: false,
                    },
                    {
                      title: "Measurement Point(s)",
                      field: "measurementPointUuid",
                      lookup: measPointLookup,
                      filtering: false,
                    },
                    {
                      title: "Reason",
                      field: "reason",
                      lookup: reasonLookup,
                    },
                  ]}
                  data={cleaningPeriods.filter(
                    (cleaningPeriod) => cleaningPeriod.measurementPointUuid
                  )}
                  editable={{
                    onRowAdd: (newItem) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          const dates = ["dateFrom", "dateTo"];
                          for (const dateString of dates) {
                            if (newItem[dateString]) {
                              newItem[dateString] = new Date(
                                newItem[dateString]
                              );
                            }
                          }

                          if (
                            Object.keys(bulkAddTypeChoices).includes(
                              newItem.measurementPointUuid
                            )
                          ) {
                            handleBulkUpdate(newItem);
                          } else {
                            setMeasPointIdToAddCleaningPeriodTo(
                              newItem.measurementPointUuid
                            );
                            setCleaningPeriodToBeAdded(newItem);
                          }
                          resolve();
                        }, 600);
                      }),
                    onRowUpdate: (newItem, oldItem) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          setCleaningPeriodToBeUpdated(newItem);
                          setOldCleaningPeriodToBeUpdated(oldItem);
                          resolve();
                        }, 600);
                      }),
                    onRowDelete: (oldItem) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          cleaningPeriodsDispatch({
                            type: actionTypes.setManyArrayFields,
                            id: oldItem.id,
                            values: {
                              measurementPointUuid: null,
                              tableData: null,
                            },
                          });
                        }, 600);
                      }),
                  }}
                  options={{
                    paging: false,
                    filtering: true,
                    rowStyle: { backgroundColor: "#F5FAEA" },
                    headerStyle: { backgroundColor: "#F5FAEA" },
                  }}
                />
              )}
            </Grid>
          </Grid>
        </SubmissionForm>
      </BaseForm>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Cleaning periods successfully updated.</DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      {submissionError && (
        <ErrorSnackbar
          errorMessage={submissionError}
          setErrorMessage={setSubmissionError}
        />
      )}
    </React.Fragment>
  );
}

export default CleaningForm;
