import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";
import { errorExists, getInputValue } from "../../utils/forms";
import NotificationIcon from "./NotificationIcon";

export default function TextField(props) {
  const {
    error,
    value,
    name,
    handleChange,
    label,
    fullWidth,
    required,
    configChangesFromServer,
    endAdornment,
    ...inputProps
  } = props;
  const hasError = errorExists(error);
  const inputValue = getInputValue(value);

  const startAdornment =
    configChangesFromServer &&
    Object.keys(configChangesFromServer).includes(name) ? (
      <NotificationIcon title="Changed Value" scale={0.5} />
    ) : (
      undefined
    );

  return (
    <FormControl error={hasError} required={required} fullWidth>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Input
        name={name}
        value={inputValue}
        onChange={handleChange}
        startAdornment={
          startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          )
        }
        endAdornment={
          endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          )
        }
        {...inputProps}
      />
      <FormHelperText hidden={!error}>{error}</FormHelperText>
    </FormControl>
  );
}
