import React from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { DateTimeFormat } from "../../constants";
import { errorExists } from "../../utils/forms";

export default function DateTimePicker(props) {
  const { error, ...otherProps } = props;
  const hasError = errorExists(error);
  return (
    <KeyboardDateTimePicker
      fullWidth
      ampm={false}
      clearable
      disableFuture
      format={DateTimeFormat}
      error={hasError}
      helperText={error}
      {...otherProps}
    />
  );
}
