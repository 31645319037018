// App setup
export const routes = {
  landingPage: "/",
  callback: "/callback",
  siteMap: "/site-map",
  measurementStation: "/measurement-station",
  uploadLoggerFile: "/upload-logger-file",
  editMeasurementStation: "/edit-measurement-station",
  cleaning: "/cleaning",
  issueLog: "/issue-log"
};

// Global styles
export const globalStyles = theme => ({
  formHeader: {
    marginTop: theme.spacing(3)
  },
  panelHeadingFont: {
    fontSize: theme.typography.pxToRem(15)
  },
  panelHeading: {
    paddingTop: theme.spacing(1)
  }
});

// Action types
export const actionTypes = {
  // Field setters
  setField: "setField",
  setManyFields: "setManyFields",
  setArrayField: "setArrayField",
  setManyArrayFields: "setManyArrayFields",
  // initializeArrayFields: "initializeArrayFields",
  addItemToArray: "addItemToArray",
  deleteItemFromArray: "deleteItemFromArray",
  setNestedField: "setNestedField",
  setNestedArrayField: "setNestedArrayField",
  init: "init",
  // Validation
  validateField: "validateField",
  validateManyFields: "validateManyFields",
  validateArrayField: "validateArrayField",
  validateArrayNestedField: "validateArrayNestedField",
  validateManyArrayFields: "validateManyArrayFields"
};

// Etc
export const DateTimeFormat = "DD/MM/YYYY HH:mm";

export const drawerWidth = 280;
