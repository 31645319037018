import React from "react";
import { Route } from "react-router-dom";
import CircularProgress from "./components/global/CircularProgress";
import auth0Client from "./Auth";

function SecuredRoute(props) {
  const { component: Component, path, checkingSession, ...otherProps } = props;
  return (
    <Route
      path={path}
      render={() => {
        if (checkingSession) return <CircularProgress />;
        if (!auth0Client.isAuthenticated()) {
          auth0Client.signIn();
          return <div />;
        }
        return <Component {...otherProps} />;
      }}
    />
  );
}

export default SecuredRoute;
