import { actionTypes } from "../../constants";
import { createReducer } from "../global/reducers";

export function issuesReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.setManyArrayFields,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray,
    actionTypes.init
  ];
  return createReducer(permittedActionTypes, state, action);
}

export function issueCommentsReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.setManyArrayFields,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray,
    actionTypes.init
  ];
  return createReducer(permittedActionTypes, state, action);
}
