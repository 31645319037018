// General helper functions that don't fit into any other utils file
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import moment from "moment-timezone";

export function isObject(value) {
  return value && typeof value === "object" && value.constructor === Object;
}

export function getDifferingFields(
  prevObject,
  nextObject,
  fieldsToIgnore = []
) {
  const differingFields = [];
  for (const field in nextObject) {
    if (
      !fieldsToIgnore.includes(field) &&
      prevObject[field] !== nextObject[field]
    ) {
      differingFields.push(field);
    }
  }

  return differingFields;
}

export function propsAreDifferent(
  prevProps,
  newProps,
  valuesName,
  arrayFields = []
) {
  const differingFields = getDifferingFields(
    prevProps[valuesName],
    newProps[valuesName]
  );

  if (differingFields.length > 0) {
    return true;
  }

  const differingErrorFields = getDifferingFields(
    prevProps.errors,
    newProps.errors
  );

  if (differingErrorFields.length > 0) {
    return true;
  }

  for (const arrayField of arrayFields) {
    // Easy check of lengths to see if columns lengths have changed
    if (prevProps[arrayField].length !== newProps[arrayField].length) {
      return true;
    }

    for (const index in prevProps[arrayField]) {
      const differingFields = getDifferingFields(
        newProps[arrayField][index],
        prevProps[arrayField][index]
      );
      if (differingFields.length > 0) {
        return true;
      }
    }
  }

  return false;
}

export function convertDate(key, values) {
  // Convert date strings to Date objects so they can be displayed in the
  // proper format.
  if (
    key.toLowerCase().includes("date") &&
    values[key] &&
    (isString(values[key]) || isNumber(values[key]))
  ) {
    return new Date(values[key]);
  } else {
    return values[key];
  }
}

export function convertAllDates(values) {
  const newValues = {};
  for (const keyOrIndex in values) {
    if (isObject(values[keyOrIndex])) {
      newValues[keyOrIndex] = {};
      // Can only handle one level deep
      for (const nestedKey in values[keyOrIndex]) {
        newValues[keyOrIndex][nestedKey] = convertDate(
          nestedKey,
          values[keyOrIndex]
        );
      }
    } else {
      newValues[keyOrIndex] = convertDate(keyOrIndex, values);
    }
  }

  return newValues;
}

export function convertEmptyStrings(value) {
  // Replace all empty strings with null to avoid scenario where
  // number text fields have a value of an empty string
  return [""].includes(value) ? null : value;
}

export function convertToCase(values, convertFn, keysToIgnore) {
  const convertedValues = {};
  for (const key in values) {
    if (keysToIgnore && keysToIgnore.includes(key)) {
      convertedValues[key] = convertEmptyStrings(values[key]);
    }
    // Date values are technically objects so need separate check for them
    else if (!isObject(values[key]) || values[key] instanceof Date) {
      let value = convertEmptyStrings(values[key]);
      if (values[key] instanceof Date) {
        value = moment.parseZone(value).format();
        // If timestamp has timezone on it, remove it
        if (value.charAt(value.length - 6) === "+") {
          value = value.substr(0, 19);
        }
      }
      // Values created manually by the DateTimePicker are of type moment. Need to convert them
      // back to Date objects and format
      if (values[key] && values[key]._isAMomentObject) {
        value = moment.parseZone(value.toDate()).format();
        // If timestamp has timezone on it, remove it
        if (value.charAt(value.length - 6) === "+") {
          value = value.substr(0, 19);
        }
      }

      convertedValues[convertFn(key)] = value;
    } else {
      const snakeKey = convertFn(key);
      convertedValues[snakeKey] = {};
      for (const innerKey in values[key]) {
        convertedValues[snakeKey][convertFn(innerKey)] = convertEmptyStrings(
          values[key][innerKey]
        );
      }
    }
  }
  return convertedValues;
}

export function formatMeasStationData(initialData) {
  // Need to convert all string date values to Date objects
  let formattedData = {};
  for (const dataKey in initialData) {
    if (
      [
        "differingLoggerFields",
        "differingSensors",
        "differingColumns",
        "extraColumns",
        "newDateFrom",
        "type",
      ].includes(dataKey)
    ) {
      formattedData[dataKey] = initialData[dataKey];
      continue;
    }

    if (Array.isArray(initialData[dataKey])) {
      formattedData[dataKey] = [];
      for (const element of initialData[dataKey]) {
        formattedData[dataKey].push(convertAllDates(element));
      }
    } else {
      formattedData[dataKey] = convertAllDates(initialData[dataKey]);
    }
  }
  return formattedData;
}

// Sort by measurementType, then by heightMetres, then by name
export function sortByMeasurementPoint(
  measPointA,
  measPointB,
  measurementTypes
) {
  // If measurement types are the same, sort by height
  if (
    measPointA.measurementType &&
    measPointB.measurementType &&
    measPointA.measurementType === measPointB.measurementType
  ) {
    // If heights are the same, sort by name (i.e. Spd1 should come before Spd2 if
    // they are both at the same height)
    if (
      measPointB.mountingArrangement.heightMetres ===
      measPointA.mountingArrangement.heightMetres
    ) {
      return measPointA.name.localeCompare(measPointB.name);
    }

    return (
      measPointB.mountingArrangement.heightMetres -
      measPointA.mountingArrangement.heightMetres
    );
  }

  const measTypes = measurementTypes.map((measType) => measType.id);

  const aMeasTypeIndex = measTypes.indexOf(measPointA.measurementType);
  const bMeasTypeIndex = measTypes.indexOf(measPointB.measurementType);

  // Order by the same order as measurementTypes appears
  return aMeasTypeIndex - bMeasTypeIndex;
}
