import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1100 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  layoutFullWidth: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  formTitle: {
    paddingBottom: theme.spacing(2)
  }
}));

export default function BaseForm({ formTitle, children, fullWidth }) {
  const classes = useStyles();
  return (
    <div className={fullWidth ? classes.layoutFullWidth : classes.layout}>
      <Paper className={classes.paper}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          className={classes.formTitle}
        >
          {formTitle}
        </Typography>
        {children}
      </Paper>
    </div>
  );
}
