import { validateRequired } from "../../utils/forms";
import { actionTypes } from "../../constants";
import {
  createReducer,
  defaultSetters,
  defaultValidators
} from "../global/reducers";

export function measLocValuesReducer(state, action) {
  const permittedActionTypes = defaultSetters;
  return createReducer(permittedActionTypes, state, action);
}

export function measLocErrorsReducer(state, action) {
  const permittedActionTypes = defaultValidators;
  return createReducer(permittedActionTypes, state, action, {
    measLocUuid: [validateRequired]
  });
}

export function cleaningPeriodsReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.setManyArrayFields,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray,
    actionTypes.init
  ];
  return createReducer(permittedActionTypes, state, action);
}
