import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { globalStyles, actionTypes } from "../../constants";
import DatePicker from "./DatePicker";
import TextField from "./TextField";
import Select from "../global/Select";
import SubmissionForm from "../global/SubmissionForm";
import { getNestedErrorValue } from "../../utils/forms";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1)
  },
  group: {
    margin: theme.spacing(1, 0)
  },
  ...globalStyles(theme)
}));

export default function CreatePlantForm({
  plantInputType,
  setPlantInputType,
  values,
  errors,
  prevPlantValues,
  prevPlantErrors,
  handlePlantValueChange,
  handlePrevPlantValueChange,
  handlePlantValuesSubmit,
  handlePrevPlantValuesSubmit,
  allPlants,
  plantTypes,
  countries,
  organisations
}) {
  const classes = useStyles();
  const theme = useTheme();
  const aboveMd = useMediaQuery(theme.breakpoints.up("md"));
  const createNewPlant = "createNewPlant";
  const usePrevPlant = "usePrevPlant";

  const handleRadioChange = event => {
    setPlantInputType(event.target.value);
  };

  const handleSubmit =
    plantInputType === createNewPlant
      ? handlePlantValuesSubmit
      : handlePrevPlantValuesSubmit;

  return (
    <SubmissionForm handleSubmit={handleSubmit} submitButtonLabel="Next">
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="Select plant input type"
                    name="plantInputSelect"
                    row={aboveMd}
                    className={classes.group}
                    value={plantInputType}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value={"createNewPlant"}
                      control={<Radio />}
                      label="Create new plant"
                    />
                    <FormControlLabel
                      value={"usePrevPlant"}
                      control={<Radio />}
                      label="Use previous plant"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {plantInputType === createNewPlant && (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="h6">General Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  error={errors.name}
                  name="name"
                  label="Plant Name"
                  value={values.name}
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "name"
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={values.plantType}
                  error={errors.plantType}
                  name="plantType"
                  label="Plant Type"
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "plantType"
                  )}
                  choices={plantTypes}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={values.country}
                  error={errors.country}
                  name="country"
                  label="Country"
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "country"
                  )}
                  choices={countries}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  type="number"
                  error={errors.latitude}
                  id="latitude"
                  name="latitude"
                  label="Latitude"
                  value={values.latitude}
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "latitude"
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  type="number"
                  error={errors.longitude}
                  id="longitude"
                  name="longitude"
                  label="Longitude"
                  value={values.longitude}
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "longitude"
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={values.developers}
                  error={errors.developers}
                  name="developers"
                  label="Developers"
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "developers"
                  )}
                  choices={organisations}
                  multiple
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={values.owners}
                  error={errors.owners}
                  name="owners"
                  label="Owners"
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "owners"
                  )}
                  choices={organisations}
                  multiple
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.formHeader} variant="h6">
                  Location Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.alias}
                  name="alias"
                  label="Plant Alias"
                  value={values.alias}
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "alias"
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.region}
                  id="region"
                  name="region"
                  label="Region"
                  value={values.region}
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "region"
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isLocationVerified}
                      onChange={handlePlantValueChange(
                        actionTypes.setField,
                        "isLocationVerified"
                      )}
                      value="isLocationVerified"
                    />
                  }
                  label="Location has been verified"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.formHeader} variant="h6">
                  Operational Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isOperational}
                      onChange={handlePlantValueChange(
                        actionTypes.setField,
                        "isOperational"
                      )}
                      value="isOperational"
                    />
                  }
                  label="Plant is operational"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  id="mecMw"
                  name="mecMw"
                  label="MEC (MW)"
                  errors={errors.mecMw}
                  value={values.mecMw}
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "mecMw"
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  id="capacityMw"
                  name="capacityMw"
                  label="Capacity"
                  error={getNestedErrorValue(
                    errors.specifications,
                    "capacityMw"
                  )}
                  value={values.specifications.capacityMw}
                  handleChange={handlePlantValueChange(
                    actionTypes.setNestedField,
                    "capacityMw",
                    { parentField: "specifications" }
                  )}
                  endAdornment="MW"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  id="turbineCount"
                  name="turbineCount"
                  label="Turbine Count"
                  errors={getNestedErrorValue(
                    errors.specifications,
                    "turbineCount"
                  )}
                  value={values.specifications.turbineCount}
                  handleChange={handlePlantValueChange(
                    actionTypes.setNestedField,
                    "turbineCount",
                    { parentField: "specifications" }
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={values.specifications.turbineOem}
                  error={getNestedErrorValue(
                    errors.specifications,
                    "turbineOem"
                  )}
                  name="turbineOem"
                  label="Turbine OEM"
                  handleChange={handlePlantValueChange(
                    actionTypes.setNestedField,
                    "turbineOem",
                    { parentField: "specifications" }
                  )}
                  choices={[{ id: "Type 1" }, { id: "Type 2" }]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="connectionId"
                  name="connectionId"
                  label="Connection Id"
                  error={getNestedErrorValue(
                    errors.connectionDetails,
                    "connectionId"
                  )}
                  value={values.connectionDetails.connectionId}
                  handleChange={handlePlantValueChange(
                    actionTypes.setNestedField,
                    "connectionId",
                    { parentField: "connectionDetails" }
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Connection Date"
                  value={values.connectionDetails.connectionDate}
                  onChange={handlePlantValueChange(
                    actionTypes.setNestedField,
                    "connectionDate",
                    { parentField: "connectionDetails" }
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  value={values.connectionDetails.systemOperatorType}
                  error={getNestedErrorValue(
                    errors.connectionDetails,
                    "systemOperatorType"
                  )}
                  name="systemOperatorType"
                  label="System Operator Type"
                  handleChange={handlePlantValueChange(
                    actionTypes.setNestedField,
                    "systemOperatorType",
                    { parentField: "connectionDetails" }
                  )}
                  choices={[{ id: "Type 1" }, { id: "Type 2" }]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  id="notes"
                  name="notes"
                  label="Notes"
                  error={errors.notes}
                  value={values.notes}
                  handleChange={handlePlantValueChange(
                    actionTypes.setField,
                    "notes"
                  )}
                />
              </Grid>
            </React.Fragment>
          )}
          {plantInputType === usePrevPlant && (
            <Grid item xs={12}>
              <Select
                value={prevPlantValues.prevPlantUuid}
                error={prevPlantErrors.prevPlantUuid}
                name="prevPlantUuid"
                label="Select Plant"
                handleChange={handlePrevPlantValueChange(
                  actionTypes.setField,
                  "prevPlantUuid"
                )}
                choices={allPlants}
                includesNone
              />
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    </SubmissionForm>
  );
}
