import { useState, useEffect } from "react";
import uuidv4 from "uuid/v4";
import { getDifferingFields } from "../../utils/misc";
import { actionTypes } from "../../constants";

export default function useTableData(
  rowsDispatch,
  parentUuidKeyAsString,
  rowDataModel
) {
  const [rowToBeAdded, setRowToBeAdded] = useState(null);
  const [rowToBeUpdated, setRowToBeUpdated] = useState(null);
  const [oldRowToBeUpdated, setOldRowToBeUpdated] = useState(null);
  const [parentUuid, setParentUuid] = useState(null);

  useEffect(
    function() {
      if (rowToBeAdded !== null) {
        const { tableData, ...row } = {
          ...rowDataModel,
          ...rowToBeAdded
        };

        rowsDispatch({
          type: actionTypes.addItemToArray,
          item: {
            id: uuidv4(),
            ...row,
            [parentUuidKeyAsString]: parentUuid
          }
        });
        setRowToBeAdded(null);
        setParentUuid(null);
      } else if (oldRowToBeUpdated !== null && rowToBeUpdated !== null) {
        const { tableData, ...row } = rowToBeUpdated;
        const differingFields = getDifferingFields(
          rowToBeUpdated,
          oldRowToBeUpdated,
          ["tableData"]
        );
        const newValues = { tableData: {} };
        for (const field of differingFields) {
          newValues[field] = row[field];
        }
        rowsDispatch({
          type: actionTypes.setManyArrayFields,
          id: oldRowToBeUpdated.id,
          values: newValues
        });
        setOldRowToBeUpdated(null);
        setRowToBeAdded(null);
      }
    },
    [
      rowToBeAdded,
      rowToBeUpdated,
      oldRowToBeUpdated,
      parentUuid,
      rowsDispatch,
      rowDataModel,
      parentUuidKeyAsString
    ]
  );

  return [
    setRowToBeAdded,
    setParentUuid,
    setRowToBeUpdated,
    setOldRowToBeUpdated
  ];
}
