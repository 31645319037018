import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(11)
  },
  activePage: {
    color: theme.palette.secondary.main
  },
  listIcon: {
    color: theme.palette.secondary.main
  }
}));

function DrawerListItem({
  handleDrawerItemClick,
  route,
  label,
  icon,
  hasChildren,
  nested,
  open
}) {
  const classes = useStyles();
  const location = useLocation();
  const Icon = icon;
  return (
    <ListItem
      button
      className={nested && classes.nested}
      onClick={handleDrawerItemClick}
    >
      {Icon && (
        <ListItemIcon className={classes.listIcon}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        className={clsx(location.pathname === route && classes.activePage)}
        primary={label}
      />
      {hasChildren && (open ? <ExpandLess /> : <ExpandMore />)}
    </ListItem>
  );
}

export default DrawerListItem;
