import { validateRequired } from "../../utils/forms";
import { actionTypes } from "../../constants";
import {
  createReducer,
  defaultSetters,
  defaultValidators
} from "../global/reducers";

export function fileValuesReducer(state, action) {
  const permittedActionTypes = defaultSetters;
  return createReducer(permittedActionTypes, state, action);
}

export function fileErrorsReducer(state, action) {
  const permittedActionTypes = defaultValidators;
  return createReducer(permittedActionTypes, state, action, {
    loggerFile: [validateRequired]
  });
}

export function prevPlantValuesReducer(state, action) {
  const permittedActionTypes = defaultSetters;
  return createReducer(permittedActionTypes, state, action);
}

export function prevPlantErrorsReducer(state, action) {
  const permittedActionTypes = defaultValidators;
  return createReducer(permittedActionTypes, state, action, {
    prevPlantUuid: [validateRequired]
  });
}

export function plantValuesReducer(state, action) {
  const permittedActionTypes = [...defaultSetters, actionTypes.setNestedField];
  return createReducer(permittedActionTypes, state, action);
}

export function plantErrorsReducer(state, action) {
  const permittedActionTypes = [...defaultValidators];
  return createReducer(permittedActionTypes, state, action, {
    name: [validateRequired],
    plantType: [validateRequired],
    country: [validateRequired],
    latitude: [validateRequired],
    longitude: [validateRequired]
  });
}

export function measLocValuesReducer(state, action) {
  const permittedActionTypes = [...defaultSetters];
  return createReducer(permittedActionTypes, state, action);
}

export function measLocErrorsReducer(state, action) {
  const permittedActionTypes = [...defaultValidators];
  return createReducer(permittedActionTypes, state, action, {
    name: [validateRequired],
    latitude: [validateRequired],
    longitude: [validateRequired],
    measurementStationType: [validateRequired]
  });
}

export function loggerValuesReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.setArrayField,
    actionTypes.setNestedArrayField,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray
  ];
  return createReducer(permittedActionTypes, state, action);
}

export function loggerErrorsReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.validateArrayField,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray,
    actionTypes.validateManyArrayFields
  ];
  return createReducer(permittedActionTypes, state, action, {
    loggerId: [validateRequired],
    loggerOem: [validateRequired],
    loggerSerialNumber: [validateRequired],
    dateFrom: [validateRequired]
  });
}

export function measPointValuesReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.setArrayField,
    actionTypes.setNestedArrayField,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray
  ];
  return createReducer(permittedActionTypes, state, action);
}

export function measPointErrorsReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.validateArrayField,
    actionTypes.validateArrayNestedField,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray,
    actionTypes.validateManyArrayFields
  ];
  return createReducer(permittedActionTypes, state, action, {
    name: [validateRequired]
  });
}

export function sensorValuesReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.setArrayField,
    actionTypes.setNestedArrayField,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray,
    actionTypes.updateSensorColumn,
    actionTypes.deleteSensorColumn
  ];
  return createReducer(permittedActionTypes, state, action);
}

export function sensorErrorsReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.validateArrayField,
    actionTypes.validateArrayNestedField,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray,
    actionTypes.validateManyArrayFields
  ];
  return createReducer(permittedActionTypes, state, action, {
    loggerMainConfigUuid: [validateRequired],
    dateFrom: [validateRequired]
  });
}

export function columnNamesReducer(state, action) {
  const permittedActionTypes = [
    actionTypes.setManyArrayFields,
    actionTypes.addItemToArray,
    actionTypes.deleteItemFromArray
  ];
  return createReducer(permittedActionTypes, state, action);
}
