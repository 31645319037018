import { useReducer } from "react";
import { getValue } from "../../utils/forms";

export default function useFormState(
  valuesReducer,
  errorsReducer,
  setIsSubmitting,
  validateAllActionType,
  initialState
) {
  const [values, valuesDispatch] = useReducer(
    valuesReducer,
    initialState || {}
  );
  const initialErrors = Array.isArray(initialState)
    ? initialState.map(item => ({
        id: item.id
      }))
    : {};
  const [errors, errorsDispatch] = useReducer(errorsReducer, initialErrors);

  const handleValueChange = (
    type,
    field,
    extraActionAttributes = {}
  ) => event => {
    const value = getValue(event);
    let action = { type, field, value, ...extraActionAttributes };
    valuesDispatch(action);
    const validateType = type.replace("set", "validate");
    errorsDispatch({ ...action, type: validateType });
  };

  const handleValuesSubmit = event => {
    event.preventDefault();
    errorsDispatch({
      type: validateAllActionType,
      values: values
    });
    setIsSubmitting(true);
  };

  return [
    values,
    errors,
    handleValueChange,
    handleValuesSubmit,
    valuesDispatch,
    errorsDispatch
  ];
}
