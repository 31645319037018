import React, { useState, useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "./components/AppBar/AppBar";
import Callback from "./Callback";
import { routes } from "./constants";
import SecuredRoute from "./SecuredRoute";
import LandingPage from "./components/LandingPage/LandingPage";
import SiteMap from "./components/SiteMap/SiteMap";
import ErrorSnackbar from "./components/global/ErrorSnackbar";
import UploadLoggerFileForm from "./components/MeasurementStations/UploadLoggerFileForm";
import EditMeasurementStationForm from "./components/MeasurementStations/EditMeasurementStationForm";
import MeasurementStationForm from "./components/MeasurementStations/MeasurementStationForm";
import auth0Client from "./Auth";
import CleaningForm from "./components/Cleaning/CleaningForm";
import IssueLogForm from "./components/IssueLog/IssueLogForm";
import MomentUtils from "@date-io/moment";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#2a333e" },
    secondary: { main: "#89ab30" },
  },
  typography: {
    fontSize: 15,
    fontFamily: ["Varela Round"].join(","),
  },
});

function App(props) {
  const [checkingSession, setCheckingSession] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const silentAuth = async () => {
      await auth0Client.silentAuth();
      // Use this instead of forceUpdate().
      setCheckingSession(false);
    };

    if (props.location.pathname === routes.callback) {
      setCheckingSession(false);
      return;
    }
    try {
      silentAuth();
    } catch (err) {
      if (err.error !== "login_required") {
        setCheckingSession(false);
        setErrorMessage(
          "Unable to login at this time. Please try again later."
        );
      }
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <React.Fragment>
          <CssBaseline />
          <AppBar checkingSession={checkingSession}>
            <Route exact path={routes.landingPage} component={LandingPage} />
            <Route exact path={routes.callback} component={Callback} />
            <SecuredRoute
              exact
              path={routes.siteMap}
              component={SiteMap}
              checkingSession={checkingSession}
            />
            <SecuredRoute
              exact
              path={routes.uploadLoggerFile}
              component={UploadLoggerFileForm}
              checkingSession={checkingSession}
              setErrorMessage={setErrorMessage}
            />
            <SecuredRoute
              exact
              path={routes.editMeasurementStation}
              component={EditMeasurementStationForm}
              checkingSession={checkingSession}
              setErrorMessage={setErrorMessage}
            />
            <SecuredRoute
              exact
              path={routes.measurementStation}
              component={MeasurementStationForm}
              checkingSession={checkingSession}
              setErrorMessage={setErrorMessage}
            />
            <SecuredRoute
              exact
              path={routes.cleaning}
              component={CleaningForm}
              checkingSession={checkingSession}
              setErrorMessage={setErrorMessage}
            />
            <SecuredRoute
              exact
              path={routes.issueLog}
              component={IssueLogForm}
              checkingSession={checkingSession}
              setErrorMessage={setErrorMessage}
            />
          </AppBar>
          {errorMessage && (
            <ErrorSnackbar
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          )}
        </React.Fragment>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default withRouter(App);
