import axios from "axios";
import { useEffect, useState } from "react";
import { getAuthHeaders } from "../../utils/http";
import { convertToCase } from "../../utils/misc";
import snakeCase from "lodash/snakeCase";
import { logAxiosError } from "../../utils/errorHandling";
import auth0Client from "../../Auth";

export default function useLogSubmit(
  items,
  url,
  extraFn,
  itemName,
  parentUuidName,
  requiredFields,
  nestedItems,
  nestedItemsUrl,
  nestedItemParentUuidName
) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  useEffect(() => {
    const submit = async () => {
      const headers = getAuthHeaders();

      for (const item of items) {
        for (const requiredField of requiredFields) {
          if (item[requiredField] === "" || item[requiredField] === null) {
            if ("date" in item) {
              setSubmissionError(
                `Error: ${itemName} with date: ${item.date} doesn't have ${requiredField}.`
              );
            } else {
              setSubmissionError(
                `Error: ${itemName} with dateFrom: ${item.dateFrom} and dateTo: ${item.dateTo} doesn't have ${requiredField}.`
              );
            }
            setIsSubmitting(false);
            return;
          }
        }

        if (item.dateFrom && item.dateTo && item.dateFrom > item.dateTo) {
          setSubmissionError(
            `Error: CleaningPeriod with dateFrom: ${item.dateFrom} and dateTo: ${item.dateTo} has a dateFrom greater than it's dateTo.`
          );
          setIsSubmitting(false);
          return;
        }
      }

      if (nestedItems) {
        for (const nestedItem of nestedItems) {
          if (nestedItem.text === "" || nestedItem.text === null) {
            setSubmissionError(
              `Error: IssueComment with date: ${nestedItem.date} doesn't have any text.`
            );
            setIsSubmitting(false);
            return;
          }
        }
      }

      for (const item of items) {
        let associatedNestedItems = [];
        if (nestedItems) {
          associatedNestedItems = nestedItems.filter(
            (nestedItem) => nestedItem[nestedItemParentUuidName] === item.id
          );
        }
        // "Deleting" an issue on the UI just removes the measurementLocationUuid. We have
        // to handle the deleting in the back end now
        if (item[parentUuidName] === null) {
          if (associatedNestedItems) {
            for (const associatedNestedItem of associatedNestedItems) {
              await axios
                .delete(
                  process.env.REACT_APP_RDB_API_URL +
                    nestedItemsUrl +
                    associatedNestedItem.id,
                  {
                    headers,
                  }
                )
                .catch((error) => {
                  if (error.response.status !== 401) {
                    return Promise.resolve();
                  } else {
                    setSubmissionError(
                      "Items not created. Please log out and log in again."
                    );
                  }
                });
            }
          }
          await axios
            .delete(process.env.REACT_APP_RDB_API_URL + url + item.id, {
              headers,
            })
            .catch((error) => {
              if (error.response.status !== 401) {
                return Promise.resolve();
              } else {
                setSubmissionError(
                  "Items not created. Please log out and log in again."
                );
              }
            });
          continue;
        }

        const { tableData, ...itemValues } = item;
        const issueSnakeValues = convertToCase(itemValues, snakeCase);
        // Always put because we don't know whether cleaning periods are new or not
        await axios
          .put(
            process.env.REACT_APP_RDB_API_URL + url + item.id,
            issueSnakeValues,
            {
              headers,
            }
          )
          .catch((error) => {
            logAxiosError(error);
          });

        for (const associatedNestedItem of associatedNestedItems) {
          // console.log(associatedNestedItem);
          const { tableData, ...nestedItemValues } = associatedNestedItem;
          const nestedItemSnakeValues = convertToCase(
            nestedItemValues,
            snakeCase
          );
          await axios
            .put(
              process.env.REACT_APP_RDB_API_URL +
                nestedItemsUrl +
                associatedNestedItem.id,
              nestedItemSnakeValues,
              {
                headers,
              }
            )
            .catch((error) => {
              logAxiosError(error);
            });
        }
      }

      // All nested items with no issueId are deleted
      if (nestedItems) {
        for (const nestedItem of nestedItems) {
          if (nestedItem.issueUuid === null) {
            await axios
              .delete(
                process.env.REACT_APP_RDB_API_URL +
                  nestedItemsUrl +
                  nestedItem.id,
                {
                  headers,
                }
              )
              .catch((error) => {
                if (error.response.status !== 401) {
                  return Promise.resolve();
                } else {
                  setSubmissionError(
                    "Items not created. Please log out and log in again."
                  );
                }
              });
          }
        }
      }
      setSubmissionError("");
      setIsSubmitting(false);
      setDialogOpen(true);
      if (extraFn) {
        extraFn();
      }
    };
    if (isSubmitting) {
      submit();
    }
  }, [
    isSubmitting,
    items,
    url,
    extraFn,
    nestedItems,
    nestedItemsUrl,
    nestedItemParentUuidName,
    itemName,
    parentUuidName,
    requiredFields,
  ]);

  return [
    dialogOpen,
    setDialogOpen,
    isSubmitting,
    setIsSubmitting,
    submissionError,
    setSubmissionError,
  ];
}
