import React from "react";
import MUIStepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
  stepper: {
    paddingBottom: theme.spacing(5)
  }
}));

function Stepper({ activeStep, steps }) {
  const classes = useStyles();
  const theme = useTheme();
  const aboveMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <React.Fragment>
      {aboveMd && (
        <MUIStepper activeStep={activeStep} className={classes.stepper}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </MUIStepper>
      )}
    </React.Fragment>
  );
}

export default Stepper;
