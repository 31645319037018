import { useState, useEffect } from "react";
import { subMinutes } from "date-fns";
import { actionTypes } from "../../constants";

export default function useItemCreation(
  addItemFn,
  handleValueChange,
  initPrevItemIds = []
) {
  const [newItems, setNewItems] = useState([]);
  const [prevItemIds, setPrevItemIds] = useState(initPrevItemIds);

  useEffect(
    function() {
      if (newItems.length > 0 && prevItemIds.length > 0) {
        for (const [idx, newItem] of newItems.entries()) {
          const oldDateTo = subMinutes(newItem.dateFrom, 1);
          const oldItemId = prevItemIds[idx];
          handleValueChange(actionTypes.setArrayField, "dateTo", {
            id: oldItemId
          })({
            target: { value: oldDateTo }
          });
          addItemFn({ ...newItem, prevId: oldItemId });
        }
        setNewItems([]);
        setPrevItemIds([]);
      }
    },
    [newItems, prevItemIds, addItemFn, handleValueChange]
  );

  return [newItems, setNewItems, setPrevItemIds];
}
