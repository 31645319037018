import { useState, useEffect } from "react";
import { getAsCamel } from "../../utils/http";

export default function useMeasurementLocations(setIsLoading) {
  const [measLocs, setMeasLocs] = useState([]);

  useEffect(() => {
    const getMeasurementLocations = async () => {
      await getAsCamel("measurement-locations").then((measLocs) => {
        setMeasLocs(measLocs);
        setIsLoading(false);
      });
    };

    setIsLoading(true);
    getMeasurementLocations();
  }, []);

  return [measLocs];
}
