import React from "react";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { errorExists } from "../../utils/forms";

export default function DatePicker(props) {
  const { error, ...otherProps } = props;
  const hasError = errorExists(error);
  return (
    <KeyboardTimePicker
      fullWidth
      ampm={false}
      clearable
      disableFuture
      openTo="year"
      format="DD/MM/YYYY"
      views={["year", "month", "date"]}
      error={hasError}
      helperText={error}
      {...otherProps}
    />
  );
}
