import React, { useState, useEffect, useReducer } from "react";
import BaseForm from "../global/BaseForm";
import moment from "moment-timezone";
import CircularProgress from "../global/CircularProgress";
import { actionTypes, DateTimeFormat } from "../../constants";
import { getAsCamel } from "../../utils/http";
import useTableData from "../global/useTableData";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import SubmissionForm from "../global/SubmissionForm";
import { getValue } from "../../utils/forms";
import { issuesReducer, issueCommentsReducer } from "./reducers";
import { Grid, Button, Typography } from "@material-ui/core";
import Select from "../global/Select";
import TextField from "../MeasurementStations/TextField";
import useMeasurementLocations from "../global/useMeasurementLocations";
import useLogSubmit from "./useLogSubmit";
import MaterialTable from "material-table";
import DateTimePicker from "../MeasurementStations/DateTimePicker";
import ErrorSnackbar from "../global/ErrorSnackbar";
import auth0Client from "../../Auth";

export const textEditComponent = (props) => (
  <TextField
    value={props.value}
    onChange={(e) => props.onChange(getValue(e))}
    multiline
  />
);

function IssueLogForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [measLocs] = useMeasurementLocations(setIsLoading);
  const [measLocUuid, setMeasLocUuid] = useState("All Unresolved Issues");
  const [issues, issuesDispatch] = useReducer(issuesReducer, []);
  const [issueComments, issueCommentsDispatch] = useReducer(
    issueCommentsReducer,
    []
  );
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [measLocToChangeTo, setMeasLocToChangeTo] = useState(false);
  const [
    dialogOpen,
    setDialogOpen,
    isSubmitting,
    setIsSubmitting,
    submissionError,
    setSubmissionError,
  ] = useLogSubmit(
    issues,
    "issues/",
    null,
    "Issue",
    "measurementLocationUuid",
    ["description"],
    issueComments,
    "issue-comments/",
    "issueUuid"
  );

  const [
    setIssueToBeAdded,
    setMeasLocIdToAddIssueTo,
    setIssueToBeUpdated,
    setOldIssueToBeUpdated,
  ] = useTableData(issuesDispatch, "measurementLocationUuid", {
    dateFrom: null,
    dateTo: null,
    description: null,
    isResolved: false,
    measurementLocationUuid: null,
  });

  const [
    setIssueCommentToBeAdded,
    setIssueIdToAddIssueCommentTo,
    setIssueCommentToBeUpdated,
    setOldIssueCommentToBeUpdated,
  ] = useTableData(issueCommentsDispatch, "issueUuid", {
    date: null,
    text: null,
    issueUuid: null,
  });
  useEffect(() => {
    const getLoggers = async () => {
      auth0Client.silentAuth().then(async () => {
        const httpParams =
          measLocUuid === "All Unresolved Issues"
            ? {
                is_resolved: false,
              }
            : {
                measurement_location_uuid: measLocUuid,
              };
        await getAsCamel("issues", httpParams)
          .then(function(issues) {
            issuesDispatch({
              type: actionTypes.init,
              item: issues,
            });
            const promises = issues.map((issue) =>
              getAsCamel("issue-comments", { issue_uuid: issue.id })
            );
            return Promise.all(promises);
          })
          .then(function(issueComments) {
            // Concatenate all periods into one array
            const allIssueComments = [].concat.apply([], issueComments);
            issueCommentsDispatch({
              type: actionTypes.init,
              item: allIssueComments,
            });
            setIsLoading(false);
          });
      });
    };

    if (measLocUuid) {
      setIsLoading(true);
      getLoggers();
    }
  }, [measLocUuid]);

  if (isLoading || isSubmitting) {
    return <CircularProgress />;
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // "Deleted" issues are just issues with no measurement location uuid. We need to filter for
  // non-deleted ones.
  let measLocIssues = issues.filter((issue) => issue.measurementLocationUuid);

  measLocIssues = measLocIssues.map((issue) => {
    issue.comments = issueComments.filter(
      (issueComment) => issueComment.issueUuid === issue.id
    );
    return issue;
  });

  const formatDate = (date) => {
    return moment(date).format(DateTimeFormat);
  };

  const editDateComponent = (props) => (
    <DateTimePicker
      // Datepicker requires value to be null if you want to display nothing.
      // props.value is undefined initially, so manually set it to null
      value={props.value || null}
      onChange={(e) => props.onChange(getValue(e))}
    />
  );

  let columns = [
    {
      title: "Date",
      field: "date",
      render: (rowData) => <Typography>{formatDate(rowData.date)}</Typography>,
      editComponent: (props) => {
        return editDateComponent(props);
      },
      customSort: (a, b) => a.date - b.date,
      filtering: false,
    },
    {
      title: "Description",
      field: "description",
      editComponent: (props) => {
        return textEditComponent(props);
      },
      filtering: false,
    },
    {
      title: "Is Resolved",
      field: "isResolved",
      type: "boolean",
    },
  ];

  if (measLocUuid === "All Unresolved Issues") {
    const measLocLookup = {};
    for (const measLoc of measLocs) {
      measLocLookup[measLoc.id] = measLoc.name;
    }
    columns = [
      {
        title: "Measurement Location",
        field: "measurementLocationUuid",
        lookup: measLocLookup,
        filtering: false,
      },
    ].concat(columns);
  }

  const handleSelectOnChange = (e) => {
    setMeasLocToChangeTo(getValue(e));
    setWarningDialogOpen(true);
  };

  const handleWarningDialogClose = () => {
    setMeasLocToChangeTo(null);
    setWarningDialogOpen(false);
  };

  const handleMeasLocChange = () => {
    setMeasLocUuid(measLocToChangeTo);
    setWarningDialogOpen(false);
    setMeasLocToChangeTo(null);
  };

  return (
    <React.Fragment>
      <BaseForm formTitle={"Issue Log"} fullWidth>
        <SubmissionForm
          handleSubmit={() => setIsSubmitting(true)}
          submitButtonLabel="Submit"
        >
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Select
                value={measLocUuid}
                label="Select Measurement Location"
                handleChange={handleSelectOnChange}
                choices={[
                  {
                    id: "All Unresolved Issues",
                    name: "All Unresolved Issues",
                  },
                ].concat(measLocs)}
              />
            </Grid>
            <Grid item xs={12}>
              {measLocUuid && (
                <MaterialTable
                  title="Issues"
                  style={{
                    backgroundColor: "#F5FAEA",
                  }}
                  columns={columns}
                  data={measLocIssues}
                  detailPanel={(issue) => {
                    return (
                      <MaterialTable
                        style={{
                          paddingLeft: 80,
                          backgroundColor: "#ebf5d6",
                        }}
                        title="Comments"
                        columns={[
                          {
                            title: "Date",
                            field: "date",
                            render: (rowData) => (
                              <Typography>
                                {formatDate(rowData.date)}
                              </Typography>
                            ),
                            editComponent: (props) => {
                              return editDateComponent(props);
                            },
                          },
                          {
                            title: "Text",
                            field: "text",
                            editComponent: (props) => {
                              return textEditComponent(props);
                            },
                          },
                        ]}
                        data={issue.comments}
                        editable={{
                          onRowAdd: (newItem) =>
                            new Promise((resolve) => {
                              setTimeout(() => {
                                const dates = ["date"];
                                for (const dateString of dates) {
                                  if (newItem[dateString]) {
                                    newItem[dateString] = new Date(
                                      newItem[dateString]
                                    );
                                  }
                                }
                                setIssueIdToAddIssueCommentTo(issue.id);
                                setIssueCommentToBeAdded(newItem);
                                resolve();
                              }, 600);
                            }),
                          onRowUpdate: (newItem, oldItem) =>
                            new Promise((resolve) => {
                              setTimeout(() => {
                                setIssueCommentToBeUpdated(newItem);
                                setOldIssueCommentToBeUpdated(oldItem);
                                resolve();
                              }, 600);
                            }),
                          onRowDelete: (oldItem) =>
                            new Promise((resolve) => {
                              setTimeout(() => {
                                resolve();
                                issueCommentsDispatch({
                                  type: actionTypes.setManyArrayFields,
                                  id: oldItem.id,
                                  values: {
                                    issueUuid: null,
                                    tableData: null,
                                  },
                                });
                              }, 600);
                            }),
                        }}
                        options={{
                          search: false,
                          paging: false,
                          rowStyle: { backgroundColor: "#ebf5d6" },
                          headerStyle: { backgroundColor: "#ebf5d6" },
                        }}
                      />
                    );
                  }}
                  editable={{
                    onRowAdd: (newItem) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          {
                            const dates = ["date"];
                            for (const dateString of dates) {
                              if (newItem[dateString]) {
                                newItem[dateString] = new Date(
                                  newItem[dateString]
                                );
                              }
                            }
                            if (measLocUuid === "All Unresolved Issues") {
                              setMeasLocIdToAddIssueTo(
                                newItem.measurementLocationUuid
                              );
                            } else {
                              setMeasLocIdToAddIssueTo(measLocUuid);
                            }
                            setIssueToBeAdded(newItem);
                          }
                          resolve();
                        }, 600);
                      }),
                    onRowUpdate: (newItem, oldItem) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          setIssueToBeUpdated(newItem);
                          setOldIssueToBeUpdated(oldItem);
                          resolve();
                        }, 600);
                      }),
                    onRowDelete: (oldItem) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          issuesDispatch({
                            type: actionTypes.setManyArrayFields,
                            id: oldItem.id,
                            values: {
                              measurementLocationUuid: null,
                              tableData: null,
                            },
                          });
                        }, 600);
                      }),
                  }}
                  options={{
                    filtering: true,
                    paging: false,
                    rowStyle: { backgroundColor: "#F5FAEA" },
                    headerStyle: { backgroundColor: "#F5FAEA" },
                  }}
                />
              )}
            </Grid>
          </Grid>
        </SubmissionForm>
      </BaseForm>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Issues successfully updated.</DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={warningDialogOpen} onClose={handleWarningDialogClose}>
        <DialogTitle>
          All unsubmitted changes will be lost. Are you sure you want to
          continue?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleWarningDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleMeasLocChange} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {submissionError && (
        <ErrorSnackbar
          errorMessage={submissionError}
          setErrorMessage={setSubmissionError}
        />
      )}
    </React.Fragment>
  );
}

export default IssueLogForm;
