import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MUISelect from "@material-ui/core/Select";
import { errorExists, getInputValue } from "../../utils/forms";

export default function Select({
  value,
  error,
  name,
  label,
  handleChange,
  choices,
  required,
  disabled,
  includesNone,
  multiple
}) {
  const hasError = errorExists(error);
  const inputValue = getInputValue(value);
  const options = includesNone
    ? [{ name: "None", id: null }].concat(choices)
    : choices;
  return (
    <FormControl error={hasError} fullWidth required={required}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <MUISelect
        required
        value={inputValue}
        onChange={handleChange}
        disabled={disabled}
        inputProps={{
          id: { name }
        }}
        multiple={multiple}
      >
        {options &&
          options.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.name || option.id}
            </MenuItem>
          ))}
      </MUISelect>
      <FormHelperText hidden={!error}>{error}</FormHelperText>
    </FormControl>
  );
}
