import axios from "axios";
import camelCase from "lodash/camelCase";
import auth0Client from "../Auth";
import { convertToCase, convertAllDates } from "./misc";
import { logAxiosError } from "./errorHandling";

export function getAuthHeaders() {
  const accessToken = auth0Client.getAccessToken();
  const headers = { Authorization: `Bearer ${accessToken}` };
  return headers;
}

// Gets data from given url endpoint and converts all object keys that
// aren't in keysToIgnore to camelCase. Keys in keysToIgnore are left as
// they are
export async function getAsCamel(url, params, keysToIgnore) {
  return await axios
    .get(process.env.REACT_APP_RDB_API_URL + url, {
      params,
      headers: getAuthHeaders(),
    })
    .then(function(response) {
      const allItems = [];
      for (const item of response.data) {
        const camelCasePlant = convertToCase(item, camelCase, keysToIgnore);
        allItems.push(convertAllDates(camelCasePlant));
      }
      return allItems;
    })
    .catch((err) => {
      logAxiosError(err);
    });
}
