import { isObject } from "./misc";

export function getValue(event) {
  let value = "";

  // Handle date picker clear
  if (event === null) {
    value = null;
  }
  // Handle date picker
  else if (!("target" in event)) {
    value = event;
  }
  // Handle file upload
  else if (event.target.files) {
    value = event.target.files[0];
  }
  // Handle checkbox
  else if (event.target.type === "checkbox") {
    value = event.target.checked;
  }
  // Handle text fields
  else {
    value = event.target.value;
  }

  return value;
}

export const validateRequired = input => {
  if (
    [null, undefined, ""].includes(input) ||
    // Check if input is an object and if it has no keys
    (Object.entries(input).length === 0 && input.constructor === Object)
  ) {
    return "This field is required";
  }
  return "";
};

export function errorExists(errorString) {
  // Error is undefined if it doesn't exist, or an empty string if there was previously an error,
  // but no longer one
  return !["", undefined, null].includes(errorString);
}

export function getInputValue(value) {
  // Use for getting all values other than date values. They should be null
  return [null, undefined].includes(value) ? "" : value;
}

export function getNestedErrorValue(errors, key) {
  return (errors && errors[key]) || "";
}

export function hasErrors(errors) {
  for (const keyOrIndex in errors) {
    if (isObject(errors[keyOrIndex])) {
      // All of our value states only have one level of nested values
      // so we dont need to do recursion. Another loop will do
      for (const nestedKey in errors[keyOrIndex]) {
        // Each error object has an id to associate it with its value object
        if (nestedKey === "id") {
          continue;
        }
        if (errors[keyOrIndex][nestedKey]) {
          return true;
        }
      }
    } else if (errors[keyOrIndex]) {
      return true;
    }
  }
  return false;
}
