import { actionTypes } from "../../constants";
import { convertDate, isObject } from "../../utils/misc";

export const defaultSetters = [actionTypes.setField, actionTypes.setManyFields];
export const defaultValidators = [
  actionTypes.validateField,
  actionTypes.validateManyFields
];

const actionTypeLookup = {
  // Field setters
  [actionTypes.setField]: setField,
  [actionTypes.setNestedField]: setNestedField,
  [actionTypes.setArrayField]: setArrayField,
  [actionTypes.setNestedArrayField]: setNestedArrayField,
  [actionTypes.addItemToArray]: addItemToArray,
  [actionTypes.deleteItemFromArray]: deleteItemFromArray,
  [actionTypes.setManyFields]: setManyFields,
  [actionTypes.setManyArrayFields]: setManyArrayFields,
  [actionTypes.init]: init,
  // Validation
  [actionTypes.validateField]: validateField,
  [actionTypes.validateArrayField]: validateArrayField,
  [actionTypes.validateManyFields]: validateManyFields,
  [actionTypes.validateManyArrayFields]: validateManyArrayFields
};

export function createReducer(
  permittedActionTypes,
  state,
  action,
  fieldsRequiringValidation
) {
  if (!permittedActionTypes.includes(action.type)) {
    return state;
  }

  // If fields requiring validation are specified, we assume that it is an error reducer.
  if (
    isObject(fieldsRequiringValidation) &&
    Object.keys(fieldsRequiringValidation).length > 0
  ) {
    return actionTypeLookup[action.type](
      state,
      action,
      fieldsRequiringValidation
    );
  }

  return actionTypeLookup[action.type](state, action);
}

function setField(state, action) {
  return { ...state, [action.field]: action.value };
}

function setNestedField(state, action) {
  return {
    ...state,
    [action.parentField]: {
      ...state[action.parentField],
      [action.field]: action.value
    }
  };
}

function getIndexById(items, id) {
  for (const [index, item] of items.entries()) {
    if (item.id === id) {
      return index;
    }
  }

  throw Error("Id does not exist in array");
}

function setArrayField(state, action) {
  const index = getIndexById(state, action.id);
  const newState = [...state];
  newState[index] = setField(newState[index], action);
  return newState;
}

function setNestedArrayField(state, action) {
  const index = getIndexById(state, action.id);
  const newState = [...state];
  newState[index] = setNestedField(newState[index], action);
  return newState;
}

function setManyFields(state, action) {
  const newValues = {};
  for (const key in action.values) {
    newValues[key] = convertDate(key, action.values);
  }
  return { ...state, ...newValues };
}

// Set many fields of the same item
function setManyArrayFields(state, action) {
  const newState = [...state];
  const index = getIndexById(state, action.id);
  newState[index] = setManyFields(newState[index], action);
  return newState;
}

function addItemToArray(state, action) {
  return [...state, action.item];
}

function deleteItemFromArray(state, action) {
  return state.filter((item, _) => item.id !== action.id);
}

function getError(field, value, validationFields) {
  if (field in validationFields) {
    const validators = validationFields[field];
    // Will return the first error
    for (const validator of validators) {
      return validator(value);
    }
  }
  return null;
}

function init(state, action) {
  return action.item;
}

function validateField(state, action, fieldsRequiringValidation) {
  const { field } = action;
  const error = getError(field, action.value, fieldsRequiringValidation);
  return { ...state, [field]: error };
}

function validateArrayField(state, action, fieldsRequiringValidation) {
  const prevState = [...state];
  const index = getIndexById(state, action.id);
  prevState[index] = validateField(
    prevState[index],
    action,
    fieldsRequiringValidation
  );
  return prevState;
}

function validateManyFields(state, action, fieldsRequiringValidation) {
  let errors = {};
  for (const field in action.values) {
    if (field in fieldsRequiringValidation) {
      const error = getError(
        field,
        action.values[field],
        fieldsRequiringValidation
      );
      errors[field] = error;
    }
  }

  return { ...state, ...errors };
}

function validateManyArrayFields(state, action, fieldsRequiringValidation) {
  const prevState = [...state];
  for (const values of action.values) {
    const index = getIndexById(state, values.id);
    prevState[index] = validateManyFields(
      prevState[index],
      { values },
      fieldsRequiringValidation
    );
  }
  return prevState;
}
